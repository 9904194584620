import React, { useEffect, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/navigation";
import "swiper/css/pagination";

// import required modules
import { useTheme } from "@mui/material/styles";
import { Keyboard, Scrollbar, Navigation } from "swiper";
import { Box, Button } from "@mui/material";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { makeStyles } from "@mui/styles";
import AOS from "aos";
import "aos/dist/aos.css";
import SliderComponent from "./SliderComponent";
import sliderImages from "./SliderImagesContent";

const useStyles = makeStyles({
  arrowBox: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: "2rem",
    position: "relative",
    alignItems: "center",
  },
  arrowbg: {
    backgroundColor: "#fff",
    padding: "10px",
    borderRadius: "5rem",
    height: "30px",
    width: "30px",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    boxShadow: "0px 0px 12px -2px #00000029",
    cursor: "pointer",
  },
  swiperBox: {
    padding: "6rem 0rem 2rem 0rem",
    "@media(max-width : 900px)": {
      padding: "2rem 0rem 2rem 0rem",
    },
  },
  scroll: {
    position: "absolute",
    top: "3.9rem",
    left: "5rem",
    right: "5rem",
  },
});

export default function DedicatedSlider() {
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = "steps".length;
  const [activeSlide, setActiveSlide] = useState(null);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <Box>
        <Swiper
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          steps={maxSteps}
          activeStep={activeStep}
          className={classes.swiperBox}
          slidesPerView={1}
          centeredSlides={false}
          slidesPerGroupSkip={4}
          grabCursor={true}
          keyboard={{
            enabled: true,
          }}
          breakpoints={{
            769: {
              slidesPerView: 4,
              slidesPerGroup: 4,
            },
          }}
          scrollbar={{
            el: ".swiper-scrollbar",
            draggable: true,
            dragSize: 160,
          }}
          navigation={{
            nextEl: ".image-swiper-button-next",
            prevEl: ".image-swiper-button-prev",
            disabledClass: "swiper-button-disabled",
          }}
          pagination={{
            clickable: true,
          }}
          modules={[Keyboard, Scrollbar, Navigation]}
        >
          {sliderImages.map((slide, index) => (
            <SwiperSlide key={index}>
              <SliderComponent
                isActive={index === activeSlide}
                setIsActive={() => setActiveSlide(index)}
                slide={slide}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <Box className={classes.arrowBox}>
          <Box className="swiper-button image-swiper-button-prev">
            <Button
              data-aos="flip-left"
              data-aos-duration="500"
              sx={{
                backgroundColor: "#fff",
                padding: "10px",
                color: "#000",
                borderRadius: "5rem",
                height: "50px",
                width: "50px",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                boxShadow: "0px 0px 12px -2px #00000029",
                cursor: "pointer",
                minWidth: "50px",
                "&:hover": {
                  backgroundColor: "#7114EF",
                  color: "#fff",
                },
              }}
              className={classes.arrowbg}
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              {theme.direction === "rtl" ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )}
            </Button>
          </Box>
          <Box className={classes.scroll}>
            <div
              class="swiper-scrollbar"
              style={{
                background: "#DFE0E6",
              }}
            >
              <div
                class="swiper-scrollbar-drag"
                style={{ background: "#7114EF", width: "25% !important" }}
              ></div>
            </div>
          </Box>
          <Box className="swiper-button image-swiper-button-next">
            <Button
              data-aos="flip-right"
              data-aos-duration="500"
              sx={{
                backgroundColor: "#fff",
                padding: "10px",
                color: "#000",
                borderRadius: "5rem",
                height: "50px",
                width: "50px",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                boxShadow: "0px 0px 12px -2px #00000029",
                cursor: "pointer",
                minWidth: "50px",
                "&:hover": {
                  backgroundColor: "#7114EF",
                  color: "#fff",
                },
              }}
              className={classes.arrowbg}
              onClick={handleNext}
              disabled={activeStep === maxSteps - 1}
            >
              {theme.direction === "rtl" ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}
