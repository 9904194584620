import React from "react";
import { Button, Typography } from "@mui/material";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { Link } from "react-router-dom";

const ServiceCard = ({ icon, title, content, handleReadMore }) => {
  console.log(content);
  return (
    <div className="col-lg-4 col-md-6 col-12 mt-4 pt-2">
      <div className="card service-wrapper rounded border-0 shadow p-4 h-100">
        <div className="icon text-center text-custom h1 shadow rounded bg-white ">
          {/* {icon} */}
          <Typography
            component={"img"}
            src={icon}
            alt="image here"
            sx={{ width: "50px", height: "50px", margin: "5px 0px" }}
          />
        </div>
        <div className="content mt-4">
          <h4 className="title">{title}</h4>
          <p className="text-muted mt-3 mb-0" >
            {content}
            <Typography component={"p"} mt={"1rem"} display={"block"} textAlign={"end"}>
              <Button variant="text" component={Link} to={handleReadMore}>
                Read more <KeyboardDoubleArrowRightIcon />
              </Button>
            </Typography>
          </p>
          <div className="mt-3"></div>
        </div>
        {/* <div className="big-icon h1 text-custom">{icon}</div> */}
        {/* <Typography component={'img'} src={icon} alt="image here"  width={100} height={100} /> */}
      </div>
    </div>
  );
};

export default ServiceCard;
