import {
  Box,
  Button,
  Container,
  Grid,
  // Link,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import footerbg from "../images/footerbg.png";
import logo from "../images/logo.svg";
import location from "../images/location.svg";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import PropTypes from "prop-types";
// import gallery1 from "../images/gallery1.svg";
// import gallery2 from "../images/gallery2.svg";
// import gallery3 from "../images/gallery3.svg";
// import gallery4 from "../images/gallery4.svg";
// import gallery5 from "../images/gallery5.svg";
// import gallery6 from "../images/gallery6.svg";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import "./footer.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { API_URl } from "../config/config";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { useFormik } from "formik";
import * as yup from "yup";

const useStyles = makeStyles({
  mainBox__wrap: {
    background: `url(${footerbg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    overflow: "hidden",
    padding: "3rem 0rem 2rem 0rem",
    "@media(max-width : 1200px)": {
      backgroundSize: "cover",
      "@media(max-width : 600px)": {
        textAlign: "center",
      },
    },
  },
  contact___btn: {
    background: "linear-gradient(145deg, #3E71E3, #591EAC)",
    backgroundColor: "transparent !important",
    color: "#fff !important",
    fontFamily: "poppins !important",
    padding: "0.5rem 1.5rem !important",
    borderRadius: "5rem !important",
    transition: "0.5s !important",
    "&:hover": {
      transform: "translateY(-5px)",
    },
  },
  para: {
    padding: "1.5rem 0rem",
  },
  fline: {
    backgroundColor: "#1475F7",
    width: "3rem",
    height: "2px",
    marginTop: "1.6rem",
    "@media(max-width:600px)": {
      width: "100%",
    },
  },
  TextField__box: {
    backgroundColor: "#141443",
    padding: "0.6rem 0.5rem",
  },
  submit: {
    background: "linear-gradient(145deg, #3E71E3, #591EAC)",
    backgroundColor: "transparent !important",
    color: "#fff !important",
    fontFamily: "poppins !important",
    padding: "0.5rem 1.5rem !important",
    borderRadius: "0rem !important",
    transition: "0.5s !important",
    textTransform: "capitalize !important",
    fontWeight: "700 !important",
    "&.Mui-disabled": {
      cursor: "not-allowed !important",
      pointerEvents: "auto !important",
      color: "rgb(255 255 255 / 38%) !important",
    },
  },
  subscribe: {
    display: "flex",
  },
  address: {
    display: "flex",
    alignItems: "flex-start",
    marginTop: "1.5rem",
  },
  gallery_box: {
    display: "flex",
    flexWrap: "wrap",
    gap: "10px",
    marginTop: "1.5rem",
    "@media(max-width : 1200px)": {
      justifyContent: "space-between",
    },
  },
  baseline: {
    backgroundColor: "#4A4CCA",
    height: "1px",
    width: "100%",
    margin: "2rem 0rem",
  },
  error: {
    color: "#f14668",
    fontSize: "14px !important",
    paddingTop: "3px",
    wordBreak: "break-all",
  },
});
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: "12px 16px 0", color: "#800080" }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          title="Close"
          sx={{
            position: "absolute",
            right: "-10px",
            color: "#fff",
            top: "-10px",
            background: "#3E71E3",
            // border:"1px solid #800080",
            borderRadius: "100%",
            "&:hover": {
              background: "#3E71E3",
            },
            // color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon sx={{ fontSize: "1.3rem" }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
const Footer = ({ handleSuccessOpen, handleMessage }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [showSnackbar, setShowSnackbar] = useState(false);

  const handleAbout = () => {
    navigate("/about");
  };

  const validationSchema = yup.object({
    email: yup
      .string()
      .required("Required")
      .trim()
      .test("Please enter valid email", function (value) {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
      }),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      let url = `${API_URl}/subscribe`;
      axios
        .post(url, values)
        .then((response) => {
          // Handle success
          if (response.status === 200) {
            setShowSnackbar(true);
            setTimeout(() => {
              setShowSnackbar(false);
            }, 4000);
            handleMessage(response.data.message);
          }
        })
        .catch((error) => {
          // Handle error
          console.error("Error:", error.message);
        });
      resetForm();
    },
  });

  return (
    <>
      <Box className={classes.mainBox__wrap}>
        <Container>
          <Grid container spacing={2}>
            <Grid item lg={3} md={3} sm={6} xs={12}>
              <Typography
                sx={{
                  padding: "4px",
                  backgroundColor: "#fff",
                }}
                component={"img"}
                src={logo}
                alt=""
              />

              <Typography className={classes.para} color={"#B2B1B1"}>
                <i className="fa fa-envelope footer-icon" />{" "}
                info@himotechglobal.com
                <Typography component={"br"} />
                <i class="fa fa-mobile-phone footer-icon phone-icon" />{" "}
                +91-7011508191
              </Typography>
              <Button className={classes.contact___btn} onClick={handleAbout}>
                About Us
              </Button>
            </Grid>
            <Grid item lg={5} md={5} sm={6} xs={12}>
              <Typography variant="h5" fontWeight={700} color={"#fff"}>
                Newsletter
              </Typography>
              <Box className={classes.fline} />
              <Typography className={classes.para} color={"#B2B1B1"}>
                Enter your e-mail to subscribe to our newsletter
              </Typography>
              <form onSubmit={formik.handleSubmit}>
                <Box className={classes.subscribe}>
                  <Box className={classes.TextField__box}>
                    <TextField
                      autoComplete="off"
                      sx={{ marginLeft: "0.6rem" }}
                      variant="standard"
                      type="email"
                      name="email"
                      value={formik.values.email}
                      placeholder="Enter Your Email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      InputProps={{
                        style: { color: "#fff" },
                        disableUnderline: true,
                      }}
                    />
                  </Box>

                  <Button
                    className={classes.submit}
                    variant="contained"
                    type="submit"
                  >
                    Submit
                  </Button>
                </Box>
                {formik.touched.email && formik.errors.email && (
                  <Typography variant="body1" className={classes.error}>
                    {formik.errors.email}
                  </Typography>
                )}
              </form>
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
              <Typography variant="h5" fontWeight={700} color={"#fff"}>
                Location
              </Typography>
              <Box className={classes.fline} />
              <Box>
                <Box className={classes.address}>
                  <Typography component={"img"} src={location} alt="" />
                  <Typography ml={1} color={"#B2B1B1"}>
                    B-01, 84, Block D, Sector 2, Noida, UP 201301
                  </Typography>
                </Box>
              </Box>
              <Typography variant="h6" fontWeight={700} mt={2} color={"#fff"}>
                Opening Hours:
              </Typography>
              <Typography color={"#B2B1B1"}>
                {" "}
                <i className="fa fa-clock-o footer-icon " />
                Mon - Sat{" "}
              </Typography>
              <Typography color={"#B2B1B1"}>09:30am - 06:30pm</Typography>
            </Grid>
            {/* <Grid item lg={3.5} md={3.5} sm={6} xs={12}>
              <Typography variant="h5" fontWeight={700} color={"#fff"}>
                Gallery
              </Typography>
              <Box className={classes.fline} />
              <Grid container spacing={1.7} sx={{ mt: "5px" }}>
                <Grid item lg={3} md={3} sm={3} xs={12}>
                  <Typography
                    component={"img"}
                    src={gallery1}
                    width={"100%"}
                    alt=""
                  />
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={12}>
                  <Typography
                    component={"img"}
                    src={gallery2}
                    width={"100%"}
                    alt=""
                  />
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={12}>
                  <Typography
                    component={"img"}
                    src={gallery3}
                    width={"100%"}
                    alt=""
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1.7} sx={{ mt: "0px" }}>
                <Grid item lg={3} md={3} sm={3} xs={12}>
                  <Typography
                    component={"img"}
                    src={gallery4}
                    width={"100%"}
                    alt=""
                  />
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={12}>
                  <Typography
                    component={"img"}
                    src={gallery5}
                    width={"100%"}
                    alt=""
                  />
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={12}>
                  <Typography
                    component={"img"}
                    src={gallery6}
                    width={"100%"}
                    alt=""
                  />
                </Grid>
              </Grid>
            </Grid> */}
          </Grid>
          <Box className={classes.baseline} />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              "@media(max-width:600px)": {
                flexDirection: "column",
                alignItems: "center",
              },
            }}
          >
            <Typography
              color={"#B2B1B1"}
              textAlign={"center"}
              fontSize={"0.95rem"}
            >
              Copyright © 2024 Himotech Global Pvt Ltd. All Rights Reserved.{" "}
              <Link
                style={{ color: "#fff", textDecoration: "none" }}
                to={"/privacy-policy"}
              >
                Privacy Policy{" "}
              </Link>
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                justifyContent: "center",
                alignItems: "center",
                "@media(max-width:600px)": {
                  paddingTop: "1rem",
                },
              }}
            >
              <a
                href="https://www.linkedin.com/company/himotech-global-pvt-ltd/"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa fa-linkedin bottom-icon" />
              </a>

              <a
                href="https://twitter.com/Himotechglobal"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa fa-twitter bottom-icon" />
              </a>

              <a
                href="https://instagram.com/himotech.global?igshid=OGQ5ZDc2ODk2ZA=="
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa fa-instagram bottom-icon" />
              </a>

              <a
                href="https://wa.me/7011508191"
                target="_blank"
                rel="noreferrer"
              >
                <WhatsAppIcon sx={{ color: "#fff", mb: "0.25rem" }} />
              </a>
            </Box>
          </Box>
          <Dialog
            onClose={() => setShowSnackbar(false)}
            aria-labelledby="customized-dialog-title"
            open={showSnackbar}
            sx={{
              "& .MuiDialogContent-root": {
                padding: "1rem",
                border: "none",
              },
              "& .MuiDialogActions-root": {
                padding: "1rem",
              },
              "& .MuiPaper-root": {
                overflowY: "inherit",
              },
              "& .MuiDialog-paper": {
                width: "500px",
                maxWidth: "1222px",
                // background: "#E4D5F4",
                border: "2px solid #3E71E3",
                borderRadius: "10px",
              },
              "& .MuiDialog-container": {
                backdropFilter: "blur(5px)",
              },
            }}
          >
            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={() => setShowSnackbar(false)}
            >
              <Typography
                sx={{
                  color: "green",
                  fontSize: "1.2rem",
                  borderBottom: "1px solid #3E71E3",
                  pb: "0.3rem",
                  mb: "0.3rem",
                  display: "flex",
                  alignItems: "center",
                  gap: "0.1rem",
                }}
              >
                <TaskAltIcon sx={{ mb: "2px" }} />
                Success
              </Typography>
            </BootstrapDialogTitle>
            <DialogContent dividers>
              <Box
                sx={{
                  textAlign: "center",
                  py: "1.5rem",
                  //   fontSize: "1.2rem",
                  color: "green",
                }}
              >
                Thanks for subscribing..
              </Box>
            </DialogContent>
          </Dialog>
        </Container>
      </Box>
    </>
  );
};

export default Footer;
