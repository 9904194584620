import web_icon from "../components/images/icons8-web-development-64.png";
import software_testing from "../components/images/icons8-software-testing-64.png";
import block_chain from "../components/images/icons8-block-chain-64.png";
import marketing from "../components/images/one_marketing.png";
import devops from "../components/images/devops-60.png";
import web_3 from "../components/images/icons8-3-season-50.png";

const popContent = [
  {
    id: 1,
    title: "WEB DEVELOPMENT",
    link: "/services/web-development",
    content: (
      <>
        {/* <li>
          Web development plays a critical role in the digital era, enabling
          businesses to thrive in a competitive market by embracing
          technological advancements.
        </li>
        <br/>
        <li>
          It encompasses the creation, construction, and maintenance of websites
          and web applications accessible via internet browsers.
        </li> */}
        At Himotech Global, we provide innovative and customized web development
        services that are tailored to meet your specific business needs. Whether
        you are looking to establish your online presence or revamp your
        existing website, our solutions are designed to enhance both your user
        experience and profitability. From startups to large enterprises, we
        deliver scalable, responsive, and user-friendly websites that drive
        results...
      </>
    ),
    modalContent: (
      <>
        <li>
          Web development plays a critical role in the digital era, enabling
          businesses to thrive in a competitive market by embracing
          technological advancements.
        </li>
        <br />
        <li>
          It encompasses the creation, construction, and maintenance of websites
          and web applications accessible via internet browsers.
        </li>
        <br />
        <li>
          Web development involves web design, programming, and database
          management, resulting in robust online platforms.
        </li>
        <br />
        <li>
          As an established IT solutions provider, we have been delivering
          high-quality web development services since 2003.
        </li>
        <br />
        <li>
          Our experienced team specializes in crafting bespoke solutions
          tailored to meet the diverse needs of our clients.
        </li>
        <br />
        <li>
          In addition to development, we prioritize enhancing our products'
          visibility and organic traffic through strategic SEO practices.
        </li>
        <br />
        <li>
          We offer a wide range of services including software deployment,
          blockchain development, WEB3 services, online marketing solutions, and
          troubleshooting support.
        </li>
        <br />
        <li>
          Our commitment to providing customized IT solutions underscores our
          dedication to empowering businesses with a distinct technological
          advantage.
        </li>
      </>
    ),
    icon: web_icon,
    show: true,
  },
  {
    id: 2,
    title: "SOFTWARE TESTING",
    link: "/services/testing",
    content: (
      <>
        {/* <li>
          Himotech emphasizes the importance of aligning development with its
          intended functionality for effective outcomes.
        </li>
        <br/>
        <li>
          We ensure a seamless experience throughout both the development and
          post-development phases.
        </li> */}
        At Himotech Global, we provide a full range of software testing and
        quality assurance services tailored to meet your web and mobile
        application needs. Our deep expertise in testing processes, combined
        with the use of industry-leading tools, ensures that your software
        products perform seamlessly, are reliable, and meet the highest
        standards. Here’s how we can support your quality assurance efforts...
      </>
    ),
    modalContent: (
      <>
        <li>
          Himotech emphasizes the importance of aligning development with its
          intended functionality for effective outcomes.
        </li>
        <br />
        <li>
          We ensure a seamless experience throughout both the development and
          post-development phases.
        </li>
        <br />
        <li>
          From UI/UX design to rigorous 3-stack working verification, we
          meticulously address every requirement, considering client demands and
          preferences.
        </li>
        <br />
        <li>
          Our testing experts, boasting years of experience, efficiently manage
          all testing procedures to deliver 100% bug-free and smoothly executed
          services.
        </li>
        <br />
        <li>
          Our testing experts, boasting years of experience, efficiently manage
          all testing procedures to deliver 100% bug-free and smoothly executed
          services.
        </li>
        <br />
        <li>
          We prioritize providing superior Manual and Automation testing
          services to ensure error-free product delivery and greater automation.
        </li>
        <br />
        <li>
          This approach not only guarantees zero bugs but also elevates your
          product's SEO priority.
        </li>
        <br />
        <li>
          Rest assured, with Himotech, your development process is in capable
          hands.
        </li>
      </>
    ),
    icon: software_testing,
  },
  {
    id: 3,
    title: "WEB 3",
    link: "/services/web3",
    content: (
      <>
        {/* <li>
          Centralization has facilitated the mass adoption of the World Wide
          Web, establishing a robust infrastructure that has served billions of
          users.
        </li>
        <br/>
        <li>
          However, this centralization has also led to a concentration of power
          in the hands of a few entities, dictating the content and rules of the
          internet.
        </li> */}
        At Himotech Global, we specialize in developing high-performance Web3
        applications that leverage blockchain technology to create innovative,
        decentralized solutions tailored to your business needs. Our expertise
        and commitment to providing secure and transparent solutions ensure that
        your business stays at the forefront of the digital revolution...
      </>
    ),
    modalContent: (
      <>
        <li>
          Centralization has facilitated the mass adoption of the World Wide
          Web, establishing a robust infrastructure that has served billions of
          users.
        </li>
        <br />
        <li>
          However, this centralization has also led to a concentration of power
          in the hands of a few entities, dictating the content and rules of the
          internet.
        </li>
        <br />
        <li>
          Web3 emerges as a solution to this issue by advocating for
          decentralization, where users play a pivotal role in building,
          operating, and owning the internet.
        </li>
        <br />
        <li>
          Unlike the current model dominated by tech giants, Web3 aims to
          empower individuals rather than corporations.
        </li>
        <br />
        <li>
          It encompasses a new vision for the internet, centered around
          principles of decentralization and user ownership.
        </li>
        <br />
        <li>
          At its core, Web3 leverages technologies such as blockchains,
          cryptocurrencies, and NFTs to democratize ownership and give users
          greater control over their online experiences.
        </li>
      </>
    ),
    icon: web_3,
  },
  {
    id: 4,
    title: "BLOCKCHAIN",
    link: "/services/blockchain",
    content: (
      <>
        {/* <li>
          Blockchain technology offers greater transparency, instant
          traceability, increased efficiency, and high security for businesses.
        </li>
        <br/>
        <li>
          It enables the creation of an immutable ledger for tracking orders,
          payments, accounts, and other transactions.
        </li> */}
        At Himotech Global, we are leading the way in the evolving world of
        blockchain technology. Our blockchain development services are designed
        to empower businesses of all sizes by leveraging the potential of
        decentralized, secure, and transparent solutions. With a dedicated team
        of experts and a strong focus on delivering reliable results, we provide
        a comprehensive range of blockchain software development services
        tailored to your unique requirements...
      </>
    ),
    modalContent: (
      <>
        <li>
          Blockchain technology offers greater transparency, instant
          traceability, increased efficiency, and high security for businesses.
        </li>
        <br />
        <li>
          It enables the creation of an immutable ledger for tracking orders,
          payments, accounts, and other transactions.
        </li>
        <br />
        <li>
          This advanced database mechanism facilitates transparent information
          sharing within a business network.
        </li>
        <br />
        <li>
          Blockchain systems come with built-in mechanisms to prevent
          unauthorized transaction entries and ensure consistency in the shared
          view of transactions.
        </li>
        <br />
        <li>
          Our team has years of expertise in providing immutability, consensus,
          and decentralization to our clients through Blockchain technology.
        </li>
        <br />
        <li>
          We assist our clients in implementing Blockchain to establish a
          non-manipulative and immutable database structure that ensures secure
          sharing of information.
        </li>
      </>
    ),
    icon: block_chain,
  },
  {
    id: 5,
    title: "MARKETING",
    link: "/services/marketing",
    content: (
      <>
        {/* <li>
          Apart from development and operations, we engage in comprehensive
          market analysis and competitor research.
        </li>
        <br/>
        <li>
          We define your firm's market positioning, pricing strategies, and
          services to ensure optimal market penetration.
        </li> */}
        Digital marketing is the process of promoting products, services, or
        brands through various digital channels and technologies. At Himotech
        Global, we help businesses harness the power of online platforms,
        including search engines, websites, social media, email, mobile apps,
        and digital advertising to connect with their target audience...
      </>
    ),
    modalContent: (
      <>
        <li>
          Apart from development and operations, we engage in comprehensive
          market analysis and competitor research.
        </li>
        <br />
        <li>
          We define your firm's market positioning, pricing strategies, and
          services to ensure optimal market penetration.
        </li>
        <br />
        <li>
          Our focus lies in promoting your products and services to the target
          audience, highlighting the benefits of working with your company.
        </li>
        <br />
        <li>
          We strive to drive demand and adoption of your products among existing
          customers.
        </li>
        <br />
        <li>
          Through strategic marketing initiatives, we guide potential customers
          through the purchasing journey.
        </li>
        <br />
        <li>
          We leverage social media and user interaction platforms to implement
          effective marketing strategies.
        </li>
        <br />
        <li>
          Our aim is to establish a meaningful social media presence for your
          business and its products, enhancing brand visibility and engagement.
        </li>
      </>
    ),
    icon: marketing,
  },
  {
    id: 6,
    // title: "DevOps Services",
    title: "DEVOPS SERVICES",
    link: "/services/devops",
    content: (
      <>
        {/* <li>
          DevOps adoption is increasingly prevalent in the IT industry, with
          organizations like Amazon, Netflix, Walmart, and Facebook embracing
          this approach.
        </li>
        <br/>
        <li>
          DevOps is characterized by a blend of cultural philosophies,
          practices, and tools aimed at enhancing an organization's ability to
          deliver applications and services rapidly.
        </li> */}
        DevOps is a transformative approach to software development and
        deployment that bridges the gap between development and operations
        teams. By fostering a culture of continuous improvement, Himotech Global
        helps businesses achieve rapid delivery and superior quality in their
        software solutions...
      </>
    ),
    modalContent: (
      <>
        <li>
          DevOps adoption is increasingly prevalent in the IT industry, with
          organizations like Amazon, Netflix, Walmart, and Facebook embracing
          this approach.
        </li>
        <br />
        <li>
          DevOps is characterized by a blend of cultural philosophies,
          practices, and tools aimed at enhancing an organization's ability to
          deliver applications and services rapidly.
        </li>
        <br />
        <li>
          Organizations utilizing DevOps experience faster product delivery and
          evolution compared to those following traditional software development
          and infrastructure management processes.
        </li>
        <br />
        <li>
          We specialize in delivering top-tier DevOps services, ensuring prompt
          and efficient product delivery while optimizing resource utilization.
        </li>
        <br />
        <li>
          Our DevOps services promote greater automation, streamlining processes
          for improved efficiency.
        </li>
        <br />
        <li>
          We facilitate seamless coordination between development and operations
          teams, leveraging DevOps methodologies to enhance collaboration and
          productivity.
        </li>
      </>
    ),
    icon: devops,
  },
];

export default popContent;
