import slidericon1 from "../images/slidericon1.svg";
import slidericonh1 from "../images/slidericonh1.svg";
import slidericon2 from "../images/slidericon2.svg";
import slidericonh2 from "../images/slidericonh2.svg";
import slidericon3 from "../images/slidericon3.svg";
import slidericonh3 from "../images/slidericonh3.svg";
import slidericon4 from "../images/slidericon4.svg";
import slidericonh4 from "../images/slidericonh4.svg";
import sliderone1 from "../images/sliderone1.svg";

const sliderImages = [
  {
    id: 0,
    icon: slidericon1,
    hoverIcon: slidericonh1,
    img: sliderone1,
    text: "Web Development",
    description: `Web development plays a critical role in the digital era, enabling businesses to
    thrive in a competitive market by embracing technological advancements`,
  },
  {
    id: 1,
    icon: slidericon3,
    hoverIcon: slidericonh3,
    img: sliderone1,
    text: "Software Testing",
    description: `Himotech emphasizes the importance of aligning development with its intended
    functionality for effective outcomes.`,
  },

  {
    id: 2,
    icon: slidericon4,
    hoverIcon: slidericonh4,
    img: sliderone1,
    text: "Web 3",
    description: `Centralization has facilitated the mass adoption of the World Wide Web,
    establishing a robust infrastructure that has served billions of users`,
  },

  {
    id: 3,
    icon: slidericon2,
    hoverIcon: slidericonh2,
    img: sliderone1,
    text: "Blockchain",
    description: `Blockchain technology offers greater transparency, instant traceability, increased
    efficiency, and high security for businesses.`,
  },

  {
    id: 4,
    icon: slidericon4,
    hoverIcon: slidericonh4,
    img: sliderone1,
    text: "Marketing Services",
    description: `Apart from development and operations, we engage in comprehensive market
    analysis and competitor research.`,
  },
  {
    id: 5,
    icon: slidericon4,
    hoverIcon: slidericonh4,
    img: sliderone1,
    text: "DevOps Services",
    description: `DevOps adoption is increasingly prevalent in the IT industry, with organizations
    like Amazon, Netflix, Walmart, and Facebook embracing this approach.`,
  },
];

export default sliderImages;
