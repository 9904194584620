import { Box, Container, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import teambg from "../images/teambg.svg";
import team1 from "../images/team1.svg";
import AOS from "aos";
import "aos/dist/aos.css";

const useStyles = makeStyles({
  mainBox__wrap: {
    background: `url(${teambg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
    padding: "6rem 0rem 6rem 0rem",
    overflow: "hidden",

    "@media(max-width : 1200px)": {
      padding: "6rem 0rem 3rem 0rem",
      "@media(max-width : 600px)": {
        padding: "1rem 0rem 1rem 0rem",
      },
    },
  },
  main_box: {
    textAlign: "center",
    "@media(max-width : 900px)": {
      paddingTop: "2rem",
      "@media(max-width : 600px)": {
        paddingTop: "0rem",
      },
    },
  },
  Top__Cn: {
    backgroundColor: "#fff",
    padding: "0.5rem 2rem",
    borderRadius: "5rem",
    display: "inline-block",
    boxShadow: "0px 0px 20px -2px #00000045",
  },
  hding: {
    padding: "1.5rem 0rem",
    "@media(max-width : 600px)": {
      fontSize: "1.5rem !important",
      padding: "2rem 0rem 1rem 0rem",
    },
  },
  br: {
    "@media(max-width : 600px)": {
      display: "none",
    },
  },
  line: {
    backgroundColor: "#002CFF",
    padding: "1px 30px",
    display: "inline-block",
  },
  bottombg: {
    backgroundColor: "#000",
    padding: "3rem 0rem 1.5rem 0rem",
    position: "absolute",
    bottom: "0",
    width: "100%",
    textAlign: "center",
    clipPath: "polygon(0 18%, 100% 0, 100% 100%, 0% 100%)",
  },
  team__box: {
    position: "relative",
    cursor: "pointer",
    transition: "0.5s",
    "&:hover": {
      transform: "translateY(-6px)",
    },
  },
});

const Team__member = [
  {
    id: 1,
    Image: team1,
    Title: "Rachna Sheth",
    Profile: "Founder & CEO",
  },
  {
    id: 2,
    Image: team1,
    Title: "Rachna Sheth",
    Profile: "Founder & CEO",
  },
  {
    id: 3,
    Image: team1,
    Title: "Rachna Sheth",
    Profile: "Founder & CEO",
  },
  {
    id: 4,
    Image: team1,
    Title: "Rachna Sheth",
    Profile: "Founder & CEO",
  },
  {
    id: 5,
    Image: team1,
    Title: "Rachna Sheth",
    Profile: "Founder & CEO",
  },
  {
    id: 6,
    Image: team1,
    Title: "Rachna Sheth",
    Profile: "Founder & CEO",
  },
  {
    id: 7,
    Image: team1,
    Title: "Rachna Sheth",
    Profile: "Founder & CEO",
  },
  {
    id: 8,
    Image: team1,
    Title: "Rachna Sheth",
    Profile: "Founder & CEO",
  },
];

const Team = () => {
  const classes = useStyles();
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <Box className={classes.mainBox__wrap} id="team">
        <Container>
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box className={classes.main_box}>
                <Typography
                  data-aos="fade-right"
                  data-aos-duration="500"
                  className={classes.Top__Cn}
                  fontWeight={700}
                  color={"#445DD6"}
                >
                  Our Team
                </Typography>
                <Typography
                  data-aos="fade-left"
                  data-aos-duration="500"
                  className={classes.hding}
                  fontWeight={900}
                  variant="h4"
                  color={"#000"}
                >
                  {/* We Serve A Wide{" "} */}
                  Get A{" "}
                  <Typography
                    className={classes.hding}
                    fontWeight={900}
                    variant="h4"
                    color={"#5333F2"}
                    component={"span"}
                  >
                    Glimpse
                    {/* Variety */}
                  </Typography>{" "}
                  Of Our <Typography className={classes.br} component={"br"} />
                  {/* Of Industries */}
                  High-Tech Members
                </Typography>
              </Box>

              <Box>
                <Grid container spacing={2}>
                  {Team__member.map((e, id) => (
                    <>
                      <Grid item lg={3} md={3} sm={6} xs={12}>
                        <Box
                          data-aos="flip-up"
                          data-aos-duration="500"
                          className={classes.team__box}
                        >
                          <Box>
                            <Typography
                              component={"img"}
                              src={e.Image}
                              alt=""
                              width={"100%"}
                            />
                          </Box>

                          <Box className={classes.bottombg}>
                            <Typography
                              variant="h5"
                              fontWeight={700}
                              color={"#fff"}
                            >
                              {e.Title}
                            </Typography>
                            <Box className={classes.line} />
                            <Typography color={"#002CFF"}>
                              {e.Profile}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    </>
                  ))}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Team;
