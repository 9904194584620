import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import testimonialbg from "../images/testimonialbg.svg";
import TestimonialSlider from "./TestimonialSlider";
import AOS from "aos";
import "aos/dist/aos.css";

const useStyles = makeStyles({
  mainBox__wrap: {
    background: `url(${testimonialbg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
    overflow: "hidden",
    padding: "3rem 0rem 3rem 0rem",
    "@media(max-width : 1200px)": {
      backgroundSize: "cover",
      "@media(max-width : 600px)": {
        padding: "1rem 0rem 3rem 0rem",
      },
    },
  },
  main_box: {
    textAlign: "left",
    "@media(max-width : 1200px)": {
      paddingTop: "2rem",
      "@media(max-width : 900px)": {
        paddingTop: "2rem",
        textAlign: "center",
      },
    },
  },
  Top__Cn: {
    backgroundColor: "#fff",
    padding: "0.5rem 2rem",
    borderRadius: "5rem",
    display: "inline-block",
    boxShadow: "0px 0px 20px -2px #00000045",
  },
  hding: {
    padding: "2rem 0rem",
    "@media(max-width : 600px)": {
      fontSize: "1.5rem !important",
      padding: "2rem 0rem 1rem 0rem",
    },
  },
  br: {
    "@media(max-width : 600px)": {
      display: "none",
    },
  },
  contact___btn: {
    background: "linear-gradient(145deg, #3E71E3, #591EAC)",
    backgroundColor: "transparent !important",
    color: "#fff !important",
    fontFamily: "poppins !important",
    padding: "0.8rem 2rem !important",
    borderRadius: "5rem !important",
    transition: "0.5s !important",
    marginTop: "2rem !important",
    "&:hover": {
      transform: "translateY(-5px)",
    },
    "@media(max-width : 900px)": {
      padding: "0.8rem 1.5rem !important",
    },
  },
});

const Testimonial = () => {
  const classes = useStyles();
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <Box className={classes.mainBox__wrap}>
        <Container>
          <Grid container spacing={2}>
            <Grid item lg={5} md={5} sm={12} xs={12}>
              <Box className={classes.main_box}>
                <Typography
                  data-aos="fade-right"
                  data-aos-duration="500"
                  className={classes.Top__Cn}
                  fontWeight={700}
                  color={"#445DD6"}
                >
                  Testimonials
                </Typography>
                <Typography
                  data-aos="fade-right"
                  data-aos-duration="500"
                  className={classes.hding}
                  fontWeight={900}
                  variant="h4"
                  color={"#000"}
                >
                  Here's what our{" "}
                  <Typography
                    className={classes.hding}
                    fontWeight={900}
                    variant="h4"
                    color={"#5333F2"}
                    component={"span"}
                  >
                    <Typography className={classes.br} component={"br"} />
                    Customer{" "}
                  </Typography>
                  have said.
                </Typography>

                <Typography
                  data-aos="fade-right"
                  data-aos-duration="500"
                  className={classes.para}
                  color={"#000"}
                >
                  If you have any specific questions or require{" "}
                  <Typography className={classes.br} component={"br"} />
                  assistance regarding the customer's feedback,
                  <Typography className={classes.br} component={"br"} /> please
                  let me know, and I'll be happy to help.
                </Typography>

                <Button
                  data-aos="fade-right"
                  data-aos-duration="500"
                  className={classes.contact___btn}
                >
                  View More
                </Button>
              </Box>
            </Grid>
            <Grid
              data-aos="fade-left"
              data-aos-duration="500"
              item
              lg={7}
              md={7}
              sm={12}
              xs={12}
            >
              <TestimonialSlider />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Testimonial;
