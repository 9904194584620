import * as React from "react";
import PropTypes from "prop-types";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import popContent from "./ServiceContent";

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle
      sx={{ m: 0, p: "12px 16px", fontFamily: "Public Sans, sans-serif" }}
      {...other}
    >
      <Typography variant="h4">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          title="Close"
          sx={{
            position: "absolute",
            right: 8,
            color: "black",
            top: 8,
          }}
        >
          <CloseIcon
            sx={{
              color: "black",
            }}
          />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
export default function SuccessPop({ handleClose, open, clickId }) {
  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={{
          "& .MuiDialogTitle-root": {
            borderBottom: "1px solid black",
            fontWeight: "bolder",
            fontFamily: "Public Sans, sans-serif",
          },
          "& .MuiDialog-paper": {
            // width: "550px",
            border: "5px solid #fff",
            borderRadius: "10px",
          },
          "& .MuiBackdrop-root": {
            backgroundColor: "transparent !important",
          },
          "& .MuiDialogContent-root": {
            border: "none",
            padding: 0,
          },
          "& .MuiDialogActions-root": {
            padding: 0,
          },
        }}
      >
        <DialogContent dividers>
          <Box
            sx={{
              textAlign: "center",
              // padding: "1rem 0.5rem",
            }}
          >
            <div class="card service-wrapper1 rounded border-0 shadow p-4">
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div class="icon text-center text-custom h1 shadow rounded bg-white ">
                  {/* <Typography component={'img'} src={popContent.map((item, i) => item.id === clickId && item.icon)} alt="image here" /> */}
                  {popContent.map(
                    (item, i) =>
                      item.id === clickId && (
                        <Typography
                          component={"img"}
                          src={item.icon}
                          alt="image here"
                        />
                      )
                  )}
                </div>
                <CloseIcon
                  sx={{
                    color: "black",
                    cursor: "pointer",
                  }}
                  onClick={handleClose}
                />
              </div>
              <div class="content mt-4">
                <h4 class="title">
                  {popContent.map(
                    (item, i) => item.id === clickId && item.title
                  )}
                </h4>
                <p class="text-muted mt-3 mb-0" style={{ textAlign: "left" }}>
                  {popContent.map(
                    (item, i) => item.id === clickId && item.modalContent
                  )}
                </p>
                <div class="mt-3"></div>
              </div>
              <div class="big-icon h1 text-custom">
                {/* <Typography component={'img'} src={popContent.map((item, i) => item.id === clickId && item.icon)} alt="image here" /> */}
                {/* {popContent.map((item, i) => item.id === clickId && item.icon)} */}
              </div>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
