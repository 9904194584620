import { Box, Typography } from "@mui/material";
import AOS from "aos";
import "aos/dist/aos.css";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import { Helmet } from "react-helmet";
import defi from "../../components/images/metaverse.jpg";
import tech_1 from "../../components/images/sepImg/tech_1.jpg";
import tech_2 from "../../components/images/sepImg/tech_2.jpg";
import tech_3 from "../../components/images/sepImg/tech_3.jpg";
import tech_4 from "../../components/images/sepImg/tech_4.jpg";
import tech_5 from "../../components/images/sepImg/tech_5.jpg";
import { useEffect } from "react";

const TechPurchase = () => {
  useEffect(() => {
    AOS.init();
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Helmet>
        <title>Understanding What Drives Tech Purchases in 2024</title>
        <meta
          name="description"
          content={`GWI’s "Tech Purchase Journey 2024" report provides valuable insights into what influences tech purchases across different generations.`}
        />
        <link
          rel="canonical"
          href="https://himotechglobal.com/blog/tech-purchase"
        />
      </Helmet>
      <Header />
      <div class="container">
        <Box
          sx={{ color: "#000", mt: "2rem" }}
          data-aos="fade-up"
          data-aos-duration="500"
        >
          <Typography
            sx={{
              fontSize: "24px",
              fontFamily: "poppins",
              // textAlign: "center",
              // pb: "1rem",
              fontWeight: "600",
            }}
          >
            Understanding What Drives Tech Purchases in 2024
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              fontFamily: "poppins",
              // textAlign: "center",
              pb: "1rem",
              fontWeight: "600",
            }}
          >
            Thu Sep 05 2024
          </Typography>
          <Typography
            sx={{
              fontSize: "20px",
              fontFamily: "poppins",
              pb: "1rem",
              textAlign: "justify",
            }}
          >
            Staying in tune with consumer trends is essential for tech and SaaS
            marketers. GWI’s "Tech Purchase Journey 2024" report provides
            valuable insights into what influences tech purchases across
            different generations.
          </Typography>

          <Typography
            sx={{
              fontSize: "24px",
              fontFamily: "poppins",
              pb: "1rem",
              fontWeight: "600",
            }}
          >
            Generational Differences in Tech Buying
          </Typography>

          <Typography
            sx={{
              fontSize: "20px",
              fontFamily: "poppins",
              pb: "1rem",
            }}
          >
            The report surveyed 15,796 internet users aged 16-64 across nine
            markets, dividing them into four generational groups: Gen Z (16-26),
            Millennials (27-40), Gen X (41-59), and Baby Boomers (60-64). The
            study reveals distinct preferences and behaviours in how these
            groups approach tech purchases.
          </Typography>
          <Typography
            sx={{
              fontSize: "24px",
              fontFamily: "poppins",
              pb: "1rem",
              fontWeight: "600",
            }}
          >
            Key Influences on Tech Purchases
          </Typography>

          <Typography
            sx={{
              fontSize: "22px",
              fontFamily: "poppins",
              pb: "1rem",
              fontWeight: "600",
            }}
          >
            Advertising and Social Media
          </Typography>
          <Typography
            component={"img"}
            src={tech_1}
            // width={"100%"}
            sx={{
              float: "right",
              p: { md: "0 1rem 0rem 1rem", xs: "0 0 1rem" },
              width: { md: "150px", xs: "30%" },
            }}
            height={"100%"}
          />
          <Typography
            sx={{
              fontSize: "20px",
              fontFamily: "poppins",
              pb: "1rem",
            }}
          >
            <b>- Gen Z and Millennials</b>: These groups are heavily influenced
            by ads in video games, influencer content, and mobile app ads.
            Social media engagement through likes and shares plays a significant
            role in their purchasing decisions.
          </Typography>
          <Typography
            sx={{
              fontSize: "20px",
              fontFamily: "poppins",
              pb: "1rem",
            }}
          >
            <b>- Gen X and Baby Boomers</b>: Traditional media such as TV
            commercials and print ads resonate more with these generations. They
            also value in-person interactions with salespeople and product
            brochures.
          </Typography>

          <Typography
            sx={{
              fontSize: "22px",
              fontFamily: "poppins",
              pb: "1rem",
              fontWeight: "600",
            }}
          >
            Social Media's Role
          </Typography>

          <Typography
            component={"img"}
            src={tech_2}
            // width={"100%"}
            sx={{
              float: "right",
              p: { md: "0 1rem 0rem 1rem", xs: "0 0 1rem" },
              width: { md: "150px", xs: "30%" },
            }}
            height={"100%"}
          />
          <Typography
            sx={{
              fontSize: "20px",
              fontFamily: "poppins",
              pb: "1rem",
            }}
          >
            Social media impacts all age groups but is especially strong among
            Gen Z and Millennials. Posts from friends, family, and influencers
            are highly persuasive.
          </Typography>
          <Typography
            sx={{
              fontSize: "22px",
              fontFamily: "poppins",
              pb: "1rem",
              fontWeight: "600",
            }}
          >
            What Drives Purchases?
          </Typography>
          <Typography
            sx={{
              fontSize: "20px",
              fontFamily: "poppins",
              pb: "1rem",
            }}
          >
            <b>- Convenience</b>: Features like Click & Collect delivery, easy
            returns, and fast online checkouts are crucial for all age groups.
          </Typography>
          <Typography
            sx={{
              fontSize: "20px",
              fontFamily: "poppins",
              pb: "1rem",
            }}
          >
            <b>- Reputation and Status</b>: Younger consumers are motivated by
            the desire to stay updated with the latest tech and enhance their
            online presence.
          </Typography>
          <Typography
            sx={{
              fontSize: "20px",
              fontFamily: "poppins",
              pb: "1rem",
            }}
          >
            <b>- Exclusive Offers</b>: Deals and exclusive content or services
            appeal to everyone.
          </Typography>
          <Typography
            sx={{
              fontSize: "22px",
              fontFamily: "poppins",
              pb: "1rem",
              fontWeight: "600",
            }}
          >
            Brand Preferences by Generation
          </Typography>
          <Typography
            component={"img"}
            src={tech_3}
            // width={"100%"}
            sx={{
              float: "right",
              p: { md: "0 1rem 0rem 1rem", xs: "0 0 1rem" },
              width: { md: "150px", xs: "30%" },
            }}
            height={"100%"}
          />
          <Typography
            sx={{
              fontSize: "20px",
              fontFamily: "poppins",
              pb: "1rem",
            }}
          >
            <b>- Gen Z</b>: Brands like HONOR and Beats by Dre are favoured for
            their innovation and cutting-edge technology.
          </Typography>
          <Typography
            sx={{
              fontSize: "20px",
              fontFamily: "poppins",
              pb: "1rem",
            }}
          >
            <b>- Millennials</b>: They lean towards brands like Bang & Olufsen
            and Meta, seeking high-quality, advanced features.
          </Typography>
          <Typography
            sx={{
              fontSize: "20px",
              fontFamily: "poppins",
              pb: "1rem",
            }}
          >
            <b>- Gen X and Baby Boomers</b>: Lenovo is a trusted brand,
            appreciated for its durability and reliability.
          </Typography>
          <Typography
            sx={{
              fontSize: "22px",
              fontFamily: "poppins",
              pb: "1rem",
              fontWeight: "600",
            }}
          >
            The Role of Smartphones
          </Typography>
          <Typography
            component={"img"}
            src={tech_4}
            // width={"100%"}
            sx={{
              float: "right",
              p: { md: "0 1rem 0rem 1rem", xs: "0 0 1rem" },
              width: { md: "150px", xs: "30%" },
            }}
            height={"100%"}
          />
          <Typography
            sx={{
              fontSize: "20px",
              fontFamily: "poppins",
              pb: "1rem",
            }}
          >
            Smartphones remain a top priority across all generations, reflecting
            the central role of mobile technology in daily life.
          </Typography>
          <Typography
            sx={{
              fontSize: "22px",
              fontFamily: "poppins",
              pb: "1rem",
              fontWeight: "600",
            }}
          >
            Strategic Takeaways for Tech Brands
          </Typography>
          <Typography
            sx={{
              fontSize: "20px",
              fontFamily: "poppins",
              pb: "1rem",
            }}
          >
            <b>- Tailored Marketing</b>: Adjust your marketing strategies to
            match the preferences of each generation. For younger audiences,
            focus on social media and online content. For older generations,
            consider traditional media and in-person demonstrations.
          </Typography>
          <Typography
            sx={{
              fontSize: "20px",
              fontFamily: "poppins",
              pb: "1rem",
            }}
          >
            <b>- Emphasise Quality and Innovation</b>: Highlight the advanced
            features and quality of your products. Use real-world examples to
            demonstrate durability and reliability, especially for older
            consumers.
          </Typography>
          <Typography
            sx={{
              fontSize: "20px",
              fontFamily: "poppins",
              pb: "1rem",
            }}
          >
            <b>- Enhance the Online Shopping Experience</b>: Streamline the
            online shopping process with features like easy returns, multiple
            payment options, and Click & Collect services to boost consumer
            trust and sales.
          </Typography>

          <Typography
            sx={{
              fontSize: "22px",
              fontFamily: "poppins",
              pb: "1rem",
              fontWeight: "600",
            }}
          >
            Call to Action
          </Typography>
          <Typography
            component={"img"}
            src={tech_5}
            // width={"100%"}
            sx={{
              float: "right",
              p: { md: "0 1rem 0rem 1rem", xs: "0 0 1rem" },
              width: { md: "150px", xs: "30%" },
            }}
            height={"100%"}
          />
          <Typography
            sx={{
              fontSize: "20px",
              fontFamily: "poppins",
              pb: "1rem",
            }}
          >
            For businesses looking to stay competitive in the tech landscape,
            understanding and catering to the unique preferences of each
            generation is key.
          </Typography>
          <Typography
            sx={{
              fontSize: "20px",
              fontFamily: "poppins",
              pb: "1rem",
            }}
          >
            Himotech Global is committed to helping businesses navigate the
            complex world of IT and technology. Since 2021, we've been
            delivering reliable service and efficient project management. Let us
            help your business build a strong identity in the digital world.
            Trust in our expertise as we continue to make technology accessible
            and useful for all.
          </Typography>
          <Typography
            sx={{
              fontSize: "20px",
              fontFamily: "poppins",
              pb: "1.5rem",
            }}
          >
            Explore how we can support your business by visiting <a href="https://himotechglobal.com/" target="_blank">Himotech
            Global</a>.
          </Typography>
        </Box>
      </div>
      <Footer />
    </div>
  );
};

export default TechPurchase;
