import { Box, Container, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import ModalForm from "./ModalForm";
import AOS from "aos";
import "aos/dist/aos.css";

const useStyles = makeStyles({
  mainBox__wrap: {
    overflow: "hidden",
    backgroundColor: "#253047",
    padding: "2rem 0rem",
    marginTop: "3rem",
    "@media(max-width : 600px)": {
      marginTop: "0rem",
    },
  },
  main_box: {
    textAlign: "center",
    "@media(max-width : 900px)": {
      paddingTop: "2rem",
    },
  },
  main_box2: {
    textAlign: "left",
    "@media(max-width : 900px)": {
      textAlign: "center",
    },
  },
  Top__Cn: {
    backgroundColor: "#fff",
    padding: "0.5rem 2rem",
    borderRadius: "5rem",
    display: "inline-block",
    boxShadow: "0px 0px 20px -2px #00000045",
  },
  hding: {
    padding: "0rem 0rem 0.5rem 0rem",
  },
  br: {
    "@media(max-width : 600px)": {
      display: "none",
    },
  },

  fingent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "@media(max-width : 900px)": {
      display: "inherit",
      textAlign: "center",
    },
  },
});

const Qoute = ({ handleSuccessOpen, handleMessage }) => {
  const classes = useStyles();
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <Box className={classes.mainBox__wrap}>
        <Container>
          <Box className={classes.fingent}>
            <Box className={classes.main_box2}>
              <Typography
                data-aos="fade-right"
                data-aos-duration="500"
                className={classes.hding}
                fontWeight={700}
                variant="h4"
                color={"#fff"}
              >
                We're available for new projects?
              </Typography>
              <Typography
                data-aos="fade-right"
                data-aos-duration="500"
                className={classes.para}
                color={"#fff"}
              >
                Maybe it's the begining of a beautiful business?
              </Typography>
            </Box>

            <Box>
              <ModalForm
                handleSuccessOpen={handleSuccessOpen}
                handleMessage={handleMessage}
              />
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Qoute;
