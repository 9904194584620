import { Box, Container, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import casebg from "../images/casebg.svg";
import CaseSlider2 from "./CaseSlider2";
import AOS from "aos";
import "aos/dist/aos.css";

const useStyles = makeStyles({
  mainBox__wrap: {
    background: `url(${casebg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
    overflow: "hidden",
    padding: "6rem 0rem 17rem 0rem",
    "@media(max-width : 1200px)": {
      padding: "4rem 0rem 3rem 0rem",
      backgroundSize: "cover",
      "@media(max-width : 600px)": {
        padding: "1rem 0rem 0.5rem 0rem",
      },
    },
  },
  main_box: {
    textAlign: "center",
    "@media(max-width : 900px)": {
      paddingTop: "2rem",
      "@media(max-width : 600px)": {
        textAlign: "center",
      },
    },
  },
  Top__Cn: {
    backgroundColor: "#fff",
    padding: "0.5rem 2rem",
    borderRadius: "5rem",
    display: "inline-block",
    boxShadow: "0px 0px 20px -2px #00000045",
  },
  hding: {
    padding: "2rem 0rem",
    "@media(max-width : 600px)": {
      fontSize: "1.5rem !important",
      padding: "2rem 0rem 1rem 0rem",
    },
  },
  br: {
    "@media(max-width : 600px)": {
      display: "none",
    },
  },
});

const Case = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  const classes = useStyles();
  return (
    <>
      <Box className={classes.mainBox__wrap}>
        <Container>
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box className={classes.main_box}>
                <Typography
                  data-aos="fade-down-right"
                  data-aos-duration="500"
                  className={classes.Top__Cn}
                  fontWeight={700}
                  color={"#445DD6"}
                >
                  Our Case Studies
                </Typography>
                <Typography
                  data-aos="fade-down-left"
                  data-aos-duration="500"
                  className={classes.hding}
                  fontWeight={900}
                  variant="h4"
                  color={"#000"}
                >
                  Technology Is{" "}
                  <Typography
                    className={classes.hding}
                    fontWeight={900}
                    variant="h4"
                    color={"#5333F2"}
                    component={"span"}
                  >
                    Transforming
                  </Typography>{" "}
                  <Typography className={classes.br} component={"br"} />
                  Every Industry Sector
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
        <CaseSlider2 />
      </Box>
    </>
  );
};

export default Case;
