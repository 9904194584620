import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import gbg from "../images/gbg.svg";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  c_box_one: {
    display: "inline-block",
    width: "240px",
    borderRadius: "8px 70px 8px 8px",
    padding: "0rem 0rem 2rem 0rem",
    textDecoration: "none",
    backgroundColor: "#fff",
    color: "#000",
    "@media(max-width : 1200px)": {
      width: "220px",
      "@media(max-width : 900px)": {
        width: "300px",
      },
    },
  },
  c_box_two: {
    display: "inline-block",
    width: "240px",
    borderRadius: "8px 70px 8px 8px",
    padding: "0rem 0rem 2rem 0rem",
    textDecoration: "none",
    backgroundColor: "#fff",
    color: "#000",
    "@media(max-width : 1200px)": {
      width: "220px",
      "@media(max-width : 900px)": {
        width: "300px",
      },
    },
  },
  imgicon: {
    marginTop: "-4rem !important",
  },
  imgicon2: {
    marginTop: "0rem !important",
  },
  bgb: {
    background: `url(${gbg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
    width: "240px",
    padding: "1rem 0rem",
    "@media(max-width : 900px)": {
      width: "100%",
      padding: "2rem 0rem",
    },
  },
  more_butn: {
    marginTop: "2rem !important",
    "&:hover": {
      backgroundColor: "transparent !important",
    },
  },
});

const SliderComponent = ({ isActive, setIsActive, slide }) => {
  const classes = useStyles();
  return (
    <Box
      data-aos={isActive ? "flip-left" : "flip-right"}
      data-aos-duration="500"
      textAlign={"center"}
      onMouseEnter={() => setIsActive(true)}
      onMouseLeave={() => setIsActive(false)}
    >
      {isActive ? (
        <>
          <Box height={71} />
          <Link className={classes.c_box_two}>
            <Typography
              className={classes.imgicon}
              component={"img"}
              src={slide.icon}
              width={140}
              alt=""
            />
            <Typography variant="h6" color={"#000"} fontWeight={600}>
              {slide.text}
            </Typography>
            {/* <Typography component={"img"} src={sliderone1} alt="" /> */}
            <Typography color={"#6B6C7E"} px={"0.3rem"}>
              {slide.description.slice(0, 100)+"..."}
            </Typography>
            <Link to={"/services"}>
              {" "}
              <Button
                className={classes.more_butn}
                disableRipple
                endIcon={<NavigateNextIcon />}
              >
                Learn More
              </Button>
            </Link>
          </Link>
        </>
      ) : (
        <>
          <Link className={classes.c_box_one}>
            <Box className={classes.bgb}>
              <Typography
                className={classes.imgicon2}
                component={"img"}
                src={slide.hoverIcon}
                width={140}
                alt=""
              />
            </Box>
            <Typography variant="h6" color={"#000"} fontWeight={600}>
              {slide.text}
            </Typography>
            <Typography color={"#6B6C7E"} px={"0.3rem"}>
              {slide.description.slice(0, 70)+"..."}
            </Typography>
            <Link to={"/services"}>
              <Button
                className={classes.more_butn}
                disableRipple
                endIcon={<NavigateNextIcon />}
              >
                Learn More
              </Button>
            </Link>
          </Link>
        </>
      )}
    </Box>
  );
};
export default SliderComponent;
