import React from "react";
import "../Style/Services.css";
import Header from "../components/header/Header";
import Qoute from "../components/homeComponent/Qoute";
import Footer from "../components/footer/Footer";
import { useState } from "react";
import SuccessPop from "./SuccessPop";
import ServiceCard from "./ServiceCard";
import popContent from "./ServiceContent";

const Services = () => {
  const [successOpen, setSuccessOpen] = useState(false);
  const handleSuccessOpen = () => setSuccessOpen(true);
  const handleSuccessClose = () => setSuccessOpen(false);
  const [clickId, setClickId] = useState();
  const handleReadMore = (id) => {
    setClickId(id);
    handleSuccessOpen();
  };
  return (
    <div>
      <Header />
      <div class="container">
        <div class="row" style={{marginTop:"2rem"}}>
          {popContent.map((item) => (
            <ServiceCard
              key={item.id}
              icon={item.icon}
              title={item.title}
              content={item.content}
              handleReadMore={item.link}
              // handleReadMore={() => handleReadMore(item.id)}
            />
          ))}
        </div>
      </div>
      <Qoute />
      <Footer />
      <SuccessPop
        open={successOpen}
        handleClose={handleSuccessClose}
        clickId={clickId}
      />
    </div>
  );
};

export default Services;
