import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    border: "none",
    padding: 0,
  },
  "& .MuiDialogActions-root": {
    padding: 0,
  },
}));
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle
      sx={{ m: 0, p: "12px 16px", fontFamily: "Public Sans, sans-serif" }}
      {...other}
    >
      <Typography variant="h4">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          title="Close"
          sx={{
            position: "absolute",
            right: 8,
            color: "black",
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
export default function SuccessPop({ handleClose, open, message }) {
  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={{
          "& .MuiDialogTitle-root": {
            borderBottom: "1px solid white",
            fontWeight: "bolder",
            fontFamily: "Public Sans, sans-serif",
          },
          "& .MuiDialog-paper": {
            width: "550px",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "bottom",
            border: "3px solid white",
          },
          "& .MuiBackdrop-root": {
            backgroundColor: "transparent !important",
          },
        }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <Typography
            component={"p"}
            sx={{
              fontSize: "1.3rem",
              fontWeight: 500,
            }}
          >
            Success
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Box
            sx={{
              textAlign: "center",
              padding: "1rem 0.5rem",
            }}
          >
            <Typography
              component="p"
              sx={{
                padding: "1rem 0.5rem",
                fontWeight: 400,
              }}
            >
              {message}
            </Typography>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
