import React from "react";
import "../Style/AboutMore.css";
import Header from "../components/header/Header";
import Qoute from "../components/homeComponent/Qoute";
import Footer from "../components/footer/Footer";
import company1 from "../components/images/Company_1.png";
import company2 from "../components/images/Company_2.png";
import company3 from "../components/images/Company_3.png";
import company4 from "../components/images/Company_4.png";
import company5 from "../components/images/Company_5.png";
import company6 from "../components/images/Company_6.png";

const AboutMore = () => {
  return (
    <>
      <Header />

      <div className="About_Page">
        <div className="Heading_About_Page">
          <h1 className="About_Page_Heading">
            About <br /> Our Company
          </h1>
        </div>
      </div>

      <div className="Second_section">
        <div className="container">
          <div className="box_img">
            <div className="box">
              <div>
                <div className="Box_1">
                  <div class="Card_1  card ">
                    <div class="card-body">
                      <p class=" card-text ">
                        The state has prosperedover the years, and a lot
                        ofdevelopment has taken place across industries,
                        Information Technology is no exception. Having said
                        that, we always felt that there was always a gap between
                        what was being showcased and what was delivered. 2011
                        was the year we decided to put our foot down & enter an
                        arena that had a significant scope of revamping. Come
                        what may, the digital side of your business will keep
                        evolving and will require a constant surveil. This is
                        where Root plays its part.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="Image1">
                  <img src={company1} alt="" />
                </div>
              </div>
              <div>
                <div className="Box_2">
                  <div class="Card_2 card">
                    <div class="card-body">
                      <p class="card-text text-center">
                        The state has prosperedover the years, and a lot
                        ofdevelopment has taken place across industries,
                        Information Technology is no exception. Having said
                        that, we always felt that there was always a gap between
                        what was being showcased and what was delivered. 2011
                        was the year we decided to put our foot down & enter an
                        arena that had a significant scope of revamping. Come
                        what may, the digital side of your business will keep
                        evolving and will require a constant surveil. This is
                        where Root plays its part.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="image2">
                  <img src={company2} alt="" />
                </div>
              </div>
              <div>
                <div className="Box_3">
                  <div class="Card_3 card">
                    <div class="card-body">
                      <p class="card-text text-center">
                        The state has prosperedover the years, and a lot
                        ofdevelopment has taken place across industries,
                        Information Technology is no exception. Having said
                        that, we always felt that there was always a gap between
                        what was being showcased and what was delivered. 2011
                        was the year we decided to put our foot down & enter an
                        arena that had a significant scope of revamping. Come
                        what may, the digital side of your business will keep
                        evolving and will require a constant surveil. This is
                        where Root plays its part.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="image3">
                  <img src={company3} alt="" />
                </div>
              </div>
              <div>
                <div className="Box_4">
                  <div class="Card_4 card">
                    <div class="card-body">
                      <p class="card-text text-center">
                        The state has prosperedover the years, and a lot
                        ofdevelopment has taken place across industries,
                        Information Technology is no exception. Having said
                        that, we always felt that there was always a gap between
                        what was being showcased and what was delivered. 2011
                        was the year we decided to put our foot down & enter an
                        arena that had a significant scope of revamping. Come
                        what may, the digital side of your business will keep
                        evolving and will require a constant surveil. This is
                        where Root plays its part.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="image4">
                  <img src={company4} alt="" />
                </div>
              </div>
              <div>
                <div className="Box_5">
                  <div class="Card_5 card">
                    <div class="card-body">
                      <p class="card-text text-center">
                        The state has prosperedover the years, and a lot
                        ofdevelopment has taken place across industries,
                        Information Technology is no exception. Having said
                        that, we always felt that there was always a gap between
                        what was being showcased and what was delivered. 2011
                        was the year we decided to put our foot down & enter an
                        arena that had a significant scope of revamping. Come
                        what may, the digital side of your business will keep
                        evolving and will require a constant surveil. This is
                        where Root plays its part.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="image5">
                  <img src={company5} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="Arrow_image">
            <img src={company6} alt="" width={"95%"} />
          </div>
        </div>
        <div className="second_background_Image">
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                <div className="first_paragraph">
                  <h6 className="Our-Mission">
                    Our <span className="Mission"> Mission</span>{" "}
                  </h6>
                  <div className="SingleLine" />
                  <div class="Line"></div>
                  <p>
                    To enhance our buyers experience by offering well crafted,
                    tailor made solutions and make them look impressive online.
                  </p>
                </div>
              </div>
              <div className="col-md-2"></div>
              <div className="col-md-5">
                <div className="Second_paragraph">
                  <h6 className="Our-Mission">
                    Our <span className="Mission"> Vision</span>
                  </h6>
                  <div className="SingleLine" />
                  <div class="Line"></div>
                  <p>
                    To enhance our buyers experience by offering well crafted,
                    tailor made solutions and make them look impressive online.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Qoute />
        <Footer />
      </div>
    </>
  );
};

export default AboutMore;
