import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import blog_bg from "../../components/images/bg_new.png";
import block_chain_img from "../../components/images/block_chain_img.png";
import devops from "../../components/images/DevOps.png";
import web_vs from "../../components/images/web_vs.png";
import blog_1 from "../../components/images/blog_new_1.jpg";
import blog_3 from "../../components/images/blog_new_5.png";
import blog_9 from "../../components/images/blog_new_7.jpg";
import img_6 from "../../components/images/image6.png";
import img_7 from "../../components/images/image9.png";
import blockchain_img from "../../components/images/blockchain_img.png";
import web_3_marketing from "../../components/images/web_3_marketing.png";
import web3_ai from "../../components/images/web3_ai.png";
import web2_web3 from "../../components/images/web2_web3.png";
import defi from "../../components/images/defi.jpeg";
import blockchain_ai from "../../components/images/blockchain_ai.avif";
import web3_identity from "../../components/images/web3_identity.webp";
import smart_contract from "../../components/images/smart_contract.webp";
import tech_1 from "../../components/images/sepImg/tech_4.jpg";
import ecom_1 from "../../components/images/sepImg/ecom_4.jpg";
import brand_1 from "../../components/images/sepImg/brand_3.jpg";
import load_1 from "../../components/images/sepImg/load_2.jpg";
import ecomN from "../../components/images/sepImg/ecom_3.jpg";
import eeat from "../../components/images/sepImg/eeat.png";
import metaverse from "../../components/images/metaverse.jpg";
import AOS from "aos";
import "aos/dist/aos.css";
import BlogHoverBox from "./BlogHoverBox";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import PropTypes from "prop-types";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { useFormik } from "formik";
import * as yup from "yup";
import { API_URl } from "../../components/config/config";
import img_2 from "../../components/images/oct_blog/img/Token development/img_2.jpg";
import img_1 from "../../components/images/oct_blog/img/Blockchain development/img_1.jpg";
import seo from "../../components/images/oct_blog/img/SEO & SEM/img_1.jpg";
import test from "../../components/images/oct_blog/img/Test management/img_2.jpg";
import payment from "../../components/images/oct_blog/img/Ecommerce/img_2.jpg";

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: "12px 16px 0", color: "#800080" }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          title="Close"
          sx={{
            position: "absolute",
            right: "-10px",
            color: "#fff",
            top: "-10px",
            background: "#3E71E3",
            // border:"1px solid #800080",
            borderRadius: "100%",
            "&:hover": {
              background: "#3E71E3",
            },
            // color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon sx={{ fontSize: "1.3rem" }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const Blogs = () => {
  const [showSnackbar, setShowSnackbar] = useState(false);

  const validationSchema = yup.object({
    email: yup
      .string()
      .required("Required")
      .trim()
      .test("Please enter valid email", function (value) {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
      }),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      let url = `${API_URl}/subscribe`;
      axios
        .post(url, values)
        .then((response) => {
          // Handle success
          if (response.status === 200) {
            setShowSnackbar(true);
            setTimeout(() => {
              setShowSnackbar(false);
            }, 4000);
          }
        })
        .catch((error) => {
          // Handle error
          console.error("Error:", error.message);
        });
      resetForm();
    },
  });
  useEffect(() => {
    AOS.init();
  }, []);

  const blogData = [
    {
      image:payment,
      link: "/blog/bitcoin-payment",
      date:"23/10/2024",
      title:"Simple Ways of Accepting Bitcoin Payments on Ecommerce Website",
      content:"Unlike the past when retailers revolved around credit cards and other forms of payment...",
      height:"320px"
      
    },
    {
      image:test,
      link: "/blog/test-management",
      date:"22/10/2024",
      title:"Test Management Tool Integrations",
      content:"Test management tools are important in ensuring quality software...",
      
    },
    {
      image:seo,
      link: "/blog/seo-vs-sem",
      date:"17/10/2024",
      title:"SEO vs. SEM - Their Interconnections and How to Use Them",
      content:"It is evident that Search Engine Optimization (SEO) and Search Engine Marketing (SEM) share a common purpose...",
    },
    {
      image:img_1,
      link: "/blog/blockchain-mvp-development",
      date:"16/10/2024",
      title:"Importance and Need for Blockchain MVP Deployment",
      content:"The development of Blockchain within Hemotech Global is gaining relevance as more and more companies...",
    },
    {
      image:img_2,
      link: "/blog/security-token",
      date:"15/10/2024",
      title:"A Brief Detail on Himotech’s Global Security Token Market ",
      content:"In the case of a project involving an Initial Coin Offering (ICO), proper marketing activities...",
      height:"320px"
    },
    {
      image:eeat,
      link: "/blog/eeat-guidline",
      date:"20/09/2024",
      title:"Understanding Google’s E-E-A-T Guidelines",
      content:"In SEO and digital marketing, keeping up with Google's evolving guidelines is essential...",
    },
    {
      image:ecomN,
      link: "/blog/ecommerce-website",
      date:"17/09/2024",
      title:"The Ultimate Guide to Building a Successful E-Commerce Website",
      content:"The e-commerce landscape is growing rapidly, offering opportunities for businesses to expand...",
    },
    {
      image:load_1,
      link: "/blog/improve-website",
      date:"15/09/2024",
      title:"MTips to Improve Your Website’s Load Times",
      content:"Website speed is a crucial factor in user experience and search engine rankings...",
    },
    {
      image:brand_1,
      link: "/blog/brand-strategy",
      date:"13/09/2024",
      title:"Revising Your Brand Strategy: When and How",
      content:"Brands, like businesses, evolve over time. Changes in your market, business focus...",
    },
    {
      image:ecom_1,
      link: "/blog/e-commerce",
      date:"10/09/2024",
      title:"E-commerce SEO: Boosting Your Online Store’s Visibility",
      content:"In today’s digital landscape, a solid SEO strategy is essential for the success of any e-commerce site...",
    },
    {
      image:tech_1,
      link: "/blog/tech-purchase",
      date:"05/09/2024",
      title:"Understanding What Drives Tech Purchases in 2024",
      content:"Staying in tune with consumer trends is essential for tech and SaaS marketers...",
    },
    {
      image:metaverse,
      link: "/blog/web3-metaverse",
      date:"21/08/2024",
      title:
        "Web 3 Metaverse Developments- Integration of AI and Blockchain in Virtual Worlds",
      content:
      "The concept of the metaverse—a collective virtual shared space, created by the convergence of virtually enhanced...",
    },
    {
      image:smart_contract,
      link: "/blog/smart-contract",
      date:"16/08/2024",
      title:
        "AI-Driven Smart Contracts: Automating and Optimizing Blockchain Agreements",
      content:
      "Smart contracts have been a fundamental innovation of blockchain technology, automating the execution...",
    },
    {
      image:web3_identity,
      link: "/blog/web3-management",
      date:"10/08/2024",
      title:
        "The Future of Web 3 Identity Management: Privacy, Security, and User Control",
      content:
      "As the internet evolves, the concept of identity management is undergoing a significant transformation...",
    },
    {
      image:blockchain_ai,
      link: "/blog/blockchain-with-ai",
      date:"05/08/2024",
      title:
        "AI-Powered Blockchain Solutions: Enhancing Security and Efficiency",
      content:
      "Artificial intelligence (AI) and blockchain technology each bring significant advancements to the digital world...",
    },
    {
      image: defi,
      link: "/blog/defi",
      date:"22/07/2024",
      title:
        "The Evolution of Decentralized Finance (DeFi) Platforms in 2024",
      content:
      "In recent years, decentralized finance (DeFi) has become a significant part of the financial landscape...",
    },
    {
      image: web_3_marketing,
      link: "/blog/digital-advertising",
      date:"06/07/2024",
      title:
        "The Future of Digital Advertising",
      content:
      "At Himotech Global, we pride ourselves on being at the forefront of IT innovation, providing reliable service...",
    },
    {
      image: web2_web3,
      link: "/blog/web2-vs-web3",
      date:"01/07/2024",
      title:
        "Web2 vs. Web3",
      content:
      "At Himotech Global, we are committed to fostering growth and innovation in the IT industry...",
    },
    {
      image: blockchain_img,
      link: "/blog/web3-technology",
      date:"30/06/2024",
      title:
        "A Beginner's Guide to the Technology Behind Web3",
      content:
      "Blockchain technology is an advanced database mechanism that allows transparent information sharing within various industry networks...",
    },
    {
      image: web3_ai,
      link: "/blog/convergence",
      date:"29/06/2024",
      title:
        "The Convergence of AI and Web3",
      content:
      "Recently, we have seen simultaneous breakthroughs in several exponential technologies...",
    },
    {
      image: img_6,
      link: "/blog/blockchain-ecosystem",
      date:"15/06/2024",
      title:
        "Top 10 Blockchain Ecosystem Protocols of 2024",
      content:
      "In 2024, blockchain technology continues to evolve, driven by innovative protocols that offer new...",
    },
    {
      image: img_7,
      link: "/blog/blockchain-rights",
      date:"03/06/2024",
      title:
        "Importance of Blockchain Intellectual Property Rights",
      content:
        "Protecting intellectual property (IP) is a significant challenge for creators...",
    },
    {
      image: block_chain_img,
      link: "/blog/blockchain",
      date:"06/05/2024",
      title:
        "The Power of Blockchain: Potential of Digital Transactions",
      content:
        "Blockchain technology has been making waves in recent years, revolutionizing various industries..",
    },
    {
      image: devops,
      link: "/blog/devops-service",
      date:"03/05/2024",
      title:
        "DevOps Services",
      content:
        "Where technology is rapidly evolving, businesses need to adapt quickly to stay ahead of the competition..",
    },
    {
      image: web_vs,
      link: "/blog/web-evolution",
      date:"01/05/2024",
      title:
        "Introduction to the evolution of the web",
      content:
        "The internet has come a long way since its inception, and with each passing phase, it has..",
    },
    {

      image: blog_1,
      date:"29/04/2024",
      link: "/blog/himotech-guide",
      title: "Himotech's Guide to Enhancing User Experience in Web Development",
      content:
        "In the whirlwind of today's digital age, where every click competes for fleeting attention..",
    },
    {

      image: blog_3,
      date:"27/04/2024",
      title: "The Future with Himotech: Exploring Web 3.0",
      link: "/blog/himotech-future",
      content:
        "Web 3.0 emerges as the harbinger of a new internet revolution..",
    },
    {
      image: blog_9,
      date:"25/04/2024",
      link: "/blog/exploring-future",
      title:
        "Exploring the Future: The 8 Biggest Web Development Trends of 2024",
      content:
        "Staying updated with the latest web development trends is essential for staying ahead..",
    },
 
  ];
  return (
    <div>
      <Header />
      <div class="container">
        <Box
          sx={{ color: "#000", mt: "1rem" }}
          data-aos="fade-up"
          data-aos-duration="500"
        >
          <Box
            sx={{
              backgroundImage: `url(${blog_bg})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "100% 100%",
              backgroundSize: "cover",
              height: "400px",
              borderRadius: "5px",
              p: "1rem",
              mb: "1.5rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Typography
                sx={{
                  fontSize: { sm: "30px", xs: "25px" },
                  fontFamily: "poppins",
                  color: "#fff",
                  // lineHeight: { sm: "2.15rem", xs: "2rem" },
                  textAlign: { sm: "center", xs: "center" },
                  pt: "6.5rem",
                  pb: "2.5rem",
                  width: { sm: "80%", xs: "100%" },
                  m: "0 auto",
                }}
              >
                Join us on a journey to discover the transformative power of
                optimal breathing!
              </Typography>
              <form onSubmit={formik.handleSubmit}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: { sm: "row", xs: "column" },
                    gap: "0.5rem",
                  }}
                >
                  <Box>
                    <TextField
                      autoComplete="off"
                      // fullWidth
                      variant="standard"
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Enter your email here..."
                      sx={{
                        borderRadius: "5px",
                        boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.15)",
                        border: "1px solid #1475F7",
                        width: { sm: "400px", xs: "100%" },
                        background: "rgb(255 255 255 / 72%)",
                        color: "#fff",
                        "& input[type=number]": {
                          "-moz-appearance": "textfield",
                        },
                        "& input[type=number]::-webkit-outer-spin-button": {
                          "-webkit-appearance": "none",
                          margin: 0,
                        },
                        "& input[type=number]::-webkit-inner-spin-button": {
                          "-webkit-appearance": "none",
                          margin: 0,
                        },
                        "&:focus-Visible": {
                          outline: "none !important",
                          border: "1px solid #1475F7",
                        },
                        "&:hover": {
                          border: "1px solid #1475F7",
                        },
                        "& .MuiInputBase-root": {
                          "&::before": {
                            display: "none",
                          },
                          "&::after": {
                            display: "none",
                          },
                        },

                        "&:focus": {
                          boxShadow: "none !important",
                          border: "1px solid #1475F7",
                          borderRadius: "10px !important ",
                        },
                        "& input": {
                          padding: "10px",
                          width: "100%",
                          color: "#000",
                          "&:focus": {
                            outline: "none",
                          },
                          "&::placeholder": {
                            color: "#000",
                            //   color: "#494949",
                          },
                        },
                      }}
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.email && formik.errors.email && (
                      <Typography
                        variant="body1"
                        sx={{
                          color: "#fff",
                          fontSize: "14px !important",
                          paddingTop: "3px",
                          fontWeight: "bold",
                          wordBreak: "break-all",
                        }}
                      >
                        {formik.errors.email}
                      </Typography>
                    )}
                  </Box>
                  <Box>
                    <Button
                      type="submit"
                      sx={{
                        background: "linear-gradient(145deg, #3E71E3, #591EAC)",
                        color: "#fff !important",
                        fontFamily: "poppins",
                        padding: "9px 8px !important",
                        lineHeight: "initial !important",
                        fontSize: "17px",
                        width: { sm: "120px", xs: "50%" },
                        "&:hover": {
                          background:
                            "linear-gradient(145deg, #3E71E3, #591EAC)",
                        },
                        display: "block",
                        m: "0 auto",
                        height: "auto",
                        border: "1px solid transparent",
                        borderRadius: "6px !important",
                        textTransform: "capitalize",
                      }}
                    >
                      Join Now
                    </Button>
                  </Box>
                </Box>
              </form>
            </Box>
          </Box>

          <Grid container spacing={2} sx={{ pb: { lg: "0" } }}>
            <Grid item md={12} sm={12} xs={12} sx={{ paddingBottom: "1.5rem" }}>
              {/* <Grid item md={6} sm={6} xs={12} sx={{paddingBottom:'5rem'}}> */}
              <Typography
                variant="h2"
                sx={{
                  fontFamily: "poppins",
                  fontSize: "2.3rem",

                  color: "#1475F7",
                  lineHeight: "2.6rem",
                  textAlign: { md: "inherit", sm: "inherit", xs: "center" },
                }}
              >
                Blogs
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} mb={"3rem"}>
            {blogData?.map((data, i) => (
              <Grid
                item
                md={4}
                sm={12}
                xs={12}
                position={"relative"}
                className="blog_1"
                m={0}
              >
                <Typography
                  component={"img"}
                  src={data.image}
                  width={"100%"}
                  height={data.height?data.height:"100%"}
                  sx={{ borderRadius: "5px", border:"1px solid #00000029", }}
                />
                <BlogHoverBox
                  content={data.content}
                  link={data.link}
                  title={data.title}
                  date={data.date}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </div>
      <Box height={"0px"} />
      <Footer />
      <Dialog
        onClose={() => setShowSnackbar(false)}
        aria-labelledby="customized-dialog-title"
        open={showSnackbar}
        sx={{
          "& .MuiDialogContent-root": {
            padding: "1rem",
            border: "none",
          },
          "& .MuiDialogActions-root": {
            padding: "1rem",
          },
          "& .MuiPaper-root": {
            overflowY: "inherit",
          },
          "& .MuiDialog-paper": {
            width: "500px",
            maxWidth: "1222px",
            // background: "#E4D5F4",
            border: "2px solid #3E71E3",
            borderRadius: "10px",
          },
          "& .MuiDialog-container": {
            backdropFilter: "blur(5px)",
          },
        }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => setShowSnackbar(false)}
        >
          <Typography
            sx={{
              color: "green",
              fontSize: "1.2rem",
              borderBottom: "1px solid #3E71E3",
              pb: "0.3rem",
              mb: "0.3rem",
              display: "flex",
              alignItems: "center",
              gap: "0.1rem",
            }}
          >
            <TaskAltIcon sx={{ mb: "2px" }} />
            Success
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Box
            sx={{
              textAlign: "center",
              py: "1.5rem",
              //   fontSize: "1.2rem",
              color: "green",
            }}
          >
            Thanks for subscribing..
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Blogs;
