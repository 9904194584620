import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import dotbg from "../images/dotbg.svg";
import whyimg from "../images/whyimg.svg";
import Strategic from "./Strategic";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  mainBox__wrap: {
    background: `url(${dotbg})`,
    overflow: "hidden",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
    padding: "0rem 0rem 6rem 0rem",
    "@media(max-width : 1200px)": {
      padding: "0rem 0rem 3rem 0rem",
      backgroundSize: "cover",
      "@media(max-width : 600px)": {
        backgroundSize: "100% 100%",
      },
    },
  },
  main_box: {
    textAlign: "center",
    paddingTop: "1rem",
    "@media(max-width : 900px)": {
      paddingTop: "2rem",
    },
  },
  main_box2: {
    textAlign: "left",
    marginTop: "3rem",
    marginLeft: "4rem",
    "@media(max-width : 1200px)": {
      marginTop: "1rem",
      "@media(max-width : 900px)": {
        textAlign: "center",
        "@media(max-width : 600px)": {
          marginLeft: "0rem",
        },
      },
    },
  },
  Top__Cn: {
    backgroundColor: "#fff",
    padding: "0.5rem 2rem",
    borderRadius: "5rem",
    display: "inline-block",
    boxShadow: "0px 0px 20px -2px #00000045",
  },
  hding: {
    padding: "1.5rem 0rem",
    "@media(max-width : 600px)": {
      fontSize: "1.5rem !important",
      padding: "2rem 0rem 1rem 0rem",
    },
  },
  br: {
    "@media(max-width : 600px)": {
      display: "none",
    },
  },
  contact___btn: {
    background: "linear-gradient(145deg, #3E71E3, #591EAC)",
    backgroundColor: "transparent !important",
    color: "#fff !important",
    fontFamily: "poppins !important",
    padding: "0.8rem 2rem !important",
    borderRadius: "5rem !important",
    transition: "0.5s !important",
    marginTop: "2rem !important",
    "&:hover": {
      transform: "translateY(-5px)",
    },
    "@media(max-width : 900px)": {
      padding: "0.8rem 1.5rem !important",
    },
  },
  fingent: {
    paddingTop: "3rem",
  },
});

const WhyChoose = () => {
  const classes = useStyles();
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <Box className={classes.mainBox__wrap}>
        <Container>
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box className={classes.main_box}>
                <Typography
                  data-aos="fade-right"
                  data-aos-duration="500"
                  className={classes.Top__Cn}
                  fontWeight={700}
                  color={"#445DD6"}
                >
                  Why choose Us?
                </Typography>
                <Typography
                  data-aos="fade-left"
                  data-aos-duration="500"
                  className={classes.hding}
                  fontWeight={900}
                  variant="h4"
                  color={"#000"}
                >
                  We Serve A Wide{" "}
                  <Typography
                    className={classes.hding}
                    fontWeight={900}
                    variant="h4"
                    color={"#5333F2"}
                    component={"span"}
                  >
                    Variety
                  </Typography>{" "}
                  <Typography className={classes.br} component={"br"} />
                  Of Industries
                </Typography>

                <Typography
                  data-aos="fade-right"
                  data-aos-duration="500"
                  color={"#000"}
                >
                  Serving a wide variety of industries can bring
                  <Typography color={"#000"} component={"span"}>
                    <Typography className={classes.br} component={"br"} />{" "}
                    several advantages to a business{" "}
                  </Typography>{" "}
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Box className={classes.fingent}>
            <Grid container spacing={2}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Box data-aos="fade-up-right" data-aos-duration="500">
                  <Typography
                    component={"img"}
                    src={whyimg}
                    width={"100%"}
                    alt=""
                  />
                </Box>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Box className={classes.main_box2}>
                  <Typography
                    data-aos="fade-up-left"
                    data-aos-duration="500"
                    fontWeight={700}
                    color={"#445DD6"}
                  >
                    Touching Lives Through Technology
                  </Typography>
                  <Typography
                    data-aos="fade-right"
                    data-aos-duration="500"
                    className={classes.hding}
                    fontWeight={900}
                    variant="h4"
                    color={"#000"}
                  >
                    Our Saga
                    {/* Fingent's Four Pillars{" "}
                    <Typography className={classes.br} component={"br"} />
                    Of Influence */}
                  </Typography>
                  <Typography
                    data-aos="fade-right"
                    data-aos-duration="500"
                    className={classes.para}
                    color={"#000"}
                  >
                    {/* "We believe in these four pillars of influence that drive
                    our <Typography className={classes.br} component={"br"} />
                    growth. This is ingrained in everything we do, right from
                    our */}
                    Himotech Global has been a well known name among ITs , known
                    for its reliable service delivery and efficient project
                    management. We have been continuously serving for you since
                    2021 to help your business get a presentable identity in the
                    world of computers. We aim leveraging the best in computer
                    science and making the IT and technology accessible and
                    useable by all, and to get it achieved, We with our team are
                    making best of efforts leading to trust from your part and
                    assurance from our side.
                    <Typography
                      data-aos="fade-right"
                      data-aos-duration="500"
                      className={classes.br}
                      component={"br"}
                    />{" "}
                  </Typography>

                  <Link to={"/about"}>
                    {" "}
                    <Button href="/about" className={classes.contact___btn}>
                      About Us
                    </Button>{" "}
                  </Link>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Strategic />
        </Container>
      </Box>
    </>
  );
};

export default WhyChoose;
