import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import bannerbg from "../images/banner__img.svg";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  Main__div: {
    background: `url(${bannerbg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
    padding: "8rem 0rem 15rem 0rem",
    "@media(max-width : 1200px)": {
      padding: "4rem 0rem 4rem 0rem",
      "@media(max-width : 900px)": {
        backgroundSize: "cover",
        padding: "4rem 0rem 6rem 0rem",
      },
    },
  },
  contact___btn: {
    background: "linear-gradient(145deg, #3E71E3, #591EAC)",
    backgroundColor: "transparent !important",
    color: "#fff !important",
    fontFamily: "poppins !important",
    padding: "0.8rem 2rem !important",
    borderRadius: "5rem !important",
    transition: "0.5s !important",
    marginTop: "2rem !important",
    "&:hover": {
      transform: "translateY(-5px)",
    },
    "@media(max-width : 900px)": {
      padding: "0.8rem 1.5rem !important",
    },
  },
  main_box: {
    textAlign: "left",
    "@media(max-width : 900px)": {
      textAlign: "left",
      "@media(max-width : 600px)": {
        textAlign: "center",
      },
    },
  },
  Top__Cn: {
    backgroundColor: "#fff",
    padding: "0.5rem 2rem",
    borderRadius: "5rem",
    display: "inline-block",
    boxShadow: "0px 0px 20px -2px #00000096",
  },
  hding: {
    padding: "2rem 0rem",
    "@media(max-width : 600px)": {
      fontSize: "2rem !important",
      padding: "2rem 0rem 1rem 0rem",
    },
  },
  br: {
    "@media(max-width : 600px)": {
      display: "none",
    },
  },
});

const Banner = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  const classes = useStyles();
  return (
    <>
      <Box className={classes.Main__div}>
        <Container>
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box className={classes.main_box}>
                <Typography
                  data-aos="fade-right"
                  data-aos-duration="500"
                  className={classes.Top__Cn}
                  fontWeight={700}
                  color={"#445DD6"}
                >
                  Serving IT To Serve You
                </Typography>
                <Typography
                  data-aos="fade-right"
                  data-aos-duration="500"
                  className={classes.hding}
                  fontWeight={900}
                  variant="h2"
                  color={"#fff"}
                >
                  Turning Codes Into Reality
                  {/* <Typography className={classes.br} component={'br'} />
                                    IT, possible! */}
                </Typography>
                <Typography
                  data-aos="fade-right"
                  data-aos-duration="500"
                  color={"#fff"}
                >
                  We are your partner for serving you upgraded IT solutions with
                  professionalism.
                </Typography>
                <Button
                  data-aos="fade-right"
                  data-aos-duration="500"
                  component={Link}
                  to="/contact"
                  className={classes.contact___btn}
                >
                  Contact Us
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Banner;
