import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import videoimg from "../images/videoimg.svg";
import { makeStyles } from "@mui/styles";
import POC_icon from "../images/POC_icon.png";
import technical from "../images/technical.svg";
import dot from "../images/dot.svg";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  mainBox__wrap: {
    position: "relative",
    overflow: "hidden",
  },
  main_box: {
    textAlign: "left",
    paddingLeft: "5rem",
    "@media(max-width : 900px)": {
      paddingLeft: "0rem",
      paddingTop: "2rem",
      "@media(max-width : 600px)": {
        textAlign: "center",
      },
    },
  },
  Top__Cn: {
    backgroundColor: "#fff",
    padding: "0.5rem 2rem",
    borderRadius: "5rem",
    display: "inline-block",
    boxShadow: "0px 0px 20px -2px #00000045",
  },
  hding: {
    padding: "2rem 0rem",
    "@media(max-width : 600px)": {
      fontSize: "1.5rem !important",
      padding: "2rem 0rem 1rem 0rem",
    },
  },
  br: {
    "@media(max-width : 600px)": {
      display: "none",
    },
  },
  contact___btn: {
    background: "linear-gradient(145deg, #3E71E3, #591EAC)",
    backgroundColor: "transparent !important",
    color: "#fff !important",
    fontFamily: "poppins !important",
    padding: "0.8rem 2rem !important",
    borderRadius: "5rem !important",

    transition: "0.5s !important",
    "&:hover": {
      transform: "translateY(-5px)",
    },
    "@media(max-width : 900px)": {
      padding: "0.8rem 1.5rem !important",
      "@media(max-width : 600px)": {
        marginRight: "0rem !important",
      },
    },
  },
  btn__box: {
    display: "flex",
    alignItems: "center",
    gap: "2rem",
    "@media(max-width : 600px)": {
      display: "inherit",
    },
  },
  cont__btm: {
    display: "flex",
    alignItems: "center",
    "@media(max-width : 600px)": {
      display: "inherit",
      paddingTop: "1rem",
    },
  },
  sub___align: {
    verticalAlign: "sub",
  },
  para: {
    paddingLeft: "1.5rem",
    borderLeft: "1px solid #5333F2",
    "@media(max-width : 600px)": {
      paddingLeft: "0rem",
      borderLeft: "1px solid transparent",
      paddingTop: "1rem",
      borderTop: "1px solid #5333F2",
    },
  },
  support_box: {
    display: "flex",
    alignItems: "center",
    "@media(max-width : 600px)": {
      display: "inherit",
    },
  },
  support_box2: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "7rem",
    "@media(max-width : 600px)": {
      paddingLeft: "0rem",
      paddingTop: "2rem",
      display: "inherit",
    },
  },
  support_box_wrap: {
    display: "flex",
    alignItems: "center",
    padding: "2rem 0rem",
    margin: "2rem 0rem",
    borderTop: "1px solid #BEBEBE",
    borderBottom: "1px solid #BEBEBE",
    "@media(max-width : 600px)": {
      display: "inherit",
    },
  },
  dotimg: {
    position: "absolute",
    right: "3rem",
    top: "-2.5rem",
    "@media(max-width : 900px)": {
      rotate: "90deg",
      top: "-5rem",
      right: "0rem",
      left: "18%",
      transform: "translate(-50%)",
      "@media(max-width : 600px)": {
        rotate: "90deg",
        top: "-5rem",
        right: "0rem",
        left: "38%",
        transform: "translate(-50%)",
      },
    },
  },
  thebest__it: {
    padding: "6rem 0rem",
    "@media(max-width : 600px)": {
      padding: "6rem 0rem 2rem 0rem",
    },
  },
});

const CompanyService = () => {
  const classes = useStyles();
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <Box className={classes.mainBox__wrap}>
        <Typography
          className={classes.dotimg}
          component={"img"}
          src={dot}
          alt=""
        />
        <Container>
          <Box className={classes.thebest__it}>
            <Grid container spacing={2}>
              <Grid item lg={4.2} md={4.2} sm={12} xs={12}>
                <Box data-aos="fade-right" data-aos-duration="500">
                  <Typography
                    component={"img"}
                    src={videoimg}
                    width={"100%"}
                    alt=""
                  />
                </Box>
              </Grid>
              <Grid item lg={7.8} md={7.8} sm={12} xs={12}>
                <Box
                  data-aos="fade-left"
                  data-aos-duration="500"
                  className={classes.main_box}
                >
                  <Typography
                    data-aos="fade-right"
                    data-aos-duration="500"
                    className={classes.Top__Cn}
                    fontWeight={700}
                    color={"#445DD6"}
                  >
                    About our Company
                  </Typography>
                  <Typography
                    data-aos="fade-right"
                    data-aos-duration="500"
                    className={classes.hding}
                    fontWeight={900}
                    variant="h4"
                    color={"#000"}
                  >
                    Choose{" "}
                    <Typography
                      className={classes.hding}
                      fontWeight={900}
                      variant="h4"
                      color={"#5333F2"}
                      component={"span"}
                    >
                      The Best
                    </Typography>{" "}
                    IT <Typography className={classes.br} component={"br"} />
                    Company
                  </Typography>
                  <Typography
                    data-aos="fade-right"
                    data-aos-duration="500"
                    className={classes.para}
                    color={"#000"}
                  >
                    We are masters in streamlining your business’s online{" "}
                    <Typography className={classes.br} component={"br"} />
                    presence customarily with our years of experienced
                    <Typography className={classes.br} component={"br"} /> staff
                    and their advancement in technology.
                  </Typography>

                  <Box className={classes.support_box_wrap}>
                    <Box
                      data-aos="fade-right"
                      data-aos-duration="500"
                      className={classes.support_box}
                    >
                      <Typography
                        mr={2}
                        component={"img"}
                        src={POC_icon}
                        width={"65px"}
                        alt=""
                      />
                      <Typography variant="h6" fontWeight={700}>
                        POC
                        <Typography className={classes.br} component={"br"} />
                        Support
                      </Typography>
                    </Box>
                    <Box
                      data-aos="fade-left"
                      data-aos-duration="500"
                      className={classes.support_box2}
                    >
                      <Typography
                        mr={2}
                        component={"img"}
                        src={technical}
                        alt=""
                      />
                      <Typography variant="h6" fontWeight={700}>
                        Technical
                        <Typography className={classes.br} component={"br"} />
                        Support
                      </Typography>
                    </Box>
                  </Box>

                  <Box className={classes.btn__box}>
                    <Box
                      data-aos="fade-right"
                      data-aos-duration="500"
                      sx={{
                        "@media(max-width : 600px)": { textAlign: "center" },
                      }}
                    >
                      <Link to={"/about"}>
                        {/* {" "} */}
                        <Button className={classes.contact___btn}>
                          About More
                        </Button>
                      </Link>
                    </Box>
                    {/* <Box data-aos="fade-left" className={classes.cont__btm}>
                                            <Box>
                                                <Typography className={classes.sub___align} component={'img'} src={telephone} width={50} alt="" />
                                            </Box>
                                            <Box ml={2}
                                                sx={{ '@media(max-width : 600px)': { marginLeft: '0rem', marginTop: '1rem' } }}
                                            >
                                                <Typography>Call for help</Typography>
                                                <Typography variant="h6" fontWeight={600} >91 0000-555-666</Typography>
                                            </Box>
                                        </Box>
                                        <Box data-aos="fade-right" sx={{ '@media(max-width : 600px)': { textAlign: 'center' } }}>
                                          <Link to={'/about'}>  <Button className={classes.contact___btn}>About More</Button></Link>
                                        </Box> */}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default CompanyService;
