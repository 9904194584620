import { Box, Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";

import React from "react";
import { makeStyles } from "@mui/styles";
import logo from "../images/logo.svg";
import MenuIcon from "@mui/icons-material/Menu";

import Dialog from "@mui/material/Dialog";
import List from "@mui/material/List";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";

const useStyles = makeStyles({
  Main__Div: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    overflow: "hidden",
    "@media(max-width : 900px)": {
      display: "none",
    },
  },
  logo__Box: {
    background: "linear-gradient(90deg, #3E71E3, #591EAC)",
    clipPath: "polygon(0 0, 86% 0, 100% 100%, 0% 100%)",
    padding: "1rem 8rem 1rem 1.5rem",
    "@media(max-width : 1200px)": {
      padding: "1rem 6rem 1rem 1.5rem",
      "@media(max-width : 900px)": {
        padding: "1rem 4rem 1rem 1.5rem",
      },
    },
  },
  logo__Box__resp: {
    background: "linear-gradient(90deg, #3E71E3, #591EAC)",
    clipPath: "polygon(0 0, 86% 0, 100% 100%, 0% 100%)",
    padding: "0.5rem 3rem 0.5rem 1rem",
  },
  Nav__list: {
    margin: 0,
    padding: 0,
    listStyle: "none",
    display: "flex",
    gap: "3rem",
  },
  list__Item: {
    color: "#000 !important",
    transition: "0.5s",
    cursor: "pointer",
    textDecoration: "none !important",
    fontFamily: "poppins !important",
    fontWeight: "500 !important",
    "&:hover": {
      color: "#445DD6 !important",
      fontWeight: "700 !important",
    },
  },
  list__Item_resp: {
    color: "#fff !important",
    transition: "0.5s",
    cursor: "pointer",
    textDecoration: "none !important",
    fontFamily: "poppins !important",
    fontWeight: "500 !important",
    "&:hover": {
      color: "#445DD6 !important",
      fontWeight: "700 !important",
    },
  },
  contact___btn: {
    background: "linear-gradient(145deg, #3E71E3, #591EAC)",
    backgroundColor: "transparent !important",
    color: "#fff !important",
    fontFamily: "poppins !important",
    padding: "0.8rem 2rem !important",
    borderRadius: "5rem !important",
    transition: "0.5s !important",
    marginRight: "1.5rem !important",
    "&:hover": {
      transform: "translateY(-5px)",
    },
    "@media(max-width : 900px)": {
      padding: "0.8rem 1.5rem !important",
      "@media(max-width : 600px)": {
        marginRight: "0rem !important",
      },
    },
  },
  logo: {
    width: "80px",
    "@media(max-width : 600px)": {
      width: "70px",
    },
  },
  Mobile_Resp: {
    display: "none",
    "@media(max-width : 900px)": {
      display: "block",
      overflow: "hidden",
    },
  },
  Mob__Div: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "linear-gradient(90deg, #3E71E3, #591EAC)",
  },
  list_resp: {
    textAlign: "center",
    backgroundColor: "#000018",
    minHeight: "100vh",
    "& li": {
      marginTop: "1rem",
    },
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

const Header = () => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const currentPath = window.location.pathname;
  return (
    <>
      <Box className={classes.Main__Div}>
        <Box className={classes.logo__Box}>
          <a href="/">
            <Typography className={classes.logo} component={"img"} src={logo} />
          </a>
        </Box>

        <Box className={classes.Nav__list} component={"ul"}>
          <Box component={"li"}>
            <Link activeClass="active" className={classes.list__Item} to="/">
              Home
            </Link>
          </Box>

          <Box component={"li"}>
            <Link
              spy={true}
              smooth={true}
              className={classes.list__Item}
              to="/about"
            >
              About
            </Link>
          </Box>

          <Box component={"li"}>
            <Link
              spy={true}
              smooth={true}
              className={classes.list__Item}
              to="/services"
            >
              Services
            </Link>
          </Box>
          <Box component={"li"}>
            <Link
              spy={true}
              smooth={true}
              className={classes.list__Item}
              to="/blog"
            >
              Blogs
            </Link>
          </Box>

          {/* {currentPath !== "/" && (
            <Box component={"li"}>
              <Link
                spy={true}
                smooth={true}
                className={classes.list__Item}
                to="/#team"
              >
                Our Team
              </Link>
            </Box>
          )} */}

          {/* <Box component={'li'}>
                        <Link spy={true} smooth={true} className={classes.list__Item} to="/aboutmore">Benefits</Link>
                    </Box> */}
          {/* {currentPath === "/" && (
            <Box component={"li"}>
              <a
                spy={true}
                smooth={true}
                className={classes.list__Item}
                href="/#team"
              >
                Our Team
              </a>
            </Box>
          )} */}
        </Box>

        <Box>
          <Button className={classes.contact___btn} component={Link} to="/contact">Contact Us</Button>
        </Box>
      </Box>

      {/* left side nav bar */}

      <Box className={classes.Mobile_Resp}>
        <Box className={classes.Mob__Div}>
          <Box className={classes.logo__Box__resp}>
            <Link to={"/"}>
              <Typography
                className={classes.logo}
                component={"img"}
                src={logo}
              />
            </Link>
          </Box>

          <Box
            sx={{
              padding: "1.2rem 1rem 1.2rem 10rem",
            }}
            onClick={handleClickOpen}
          >
            <MenuIcon sx={{ color: "#fff" }} />
          </Box>
        </Box>

        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: "relative" }}>
            <Toolbar
              sx={{
                backgroundColor: "#000018",
                borderBottom: "1px solid #7114EF",
              }}
            >
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <List className={classes.list_resp}>
            <Box component={"li"}>
              <Link
                activeClass="active"
                onClick={handleClose}
                className={classes.list__Item_resp}
                to="/"
              >
                Home
              </Link>
            </Box>
            <Box component={"li"}>
              <Link
                spy={true}
                smooth={true}
                onClick={handleClose}
                className={classes.list__Item_resp}
                to="/about"
              >
                About
              </Link>
            </Box>
            <Box component={"li"}>
              <Link
                spy={true}
                smooth={true}
                onClick={handleClose}
                className={classes.list__Item_resp}
                to="/services"
              >
                Services
              </Link>
            </Box>
            <Box component={"li"}>
              <Link
                spy={true}
                smooth={true}
                onClick={handleClose}
                className={classes.list__Item_resp}
                to="/blog"
              >
                Blogs
              </Link>
            </Box>
            {/* <Box component={"li"}>
              <Link
                spy={true}
                smooth={true}
                onClick={handleClose}
                className={classes.list__Item_resp}
                to="benefits"
              >
                Benefits
              </Link>
            </Box> */}
            {/* <Box component={"li"}>
              <Link
                spy={true}
                smooth={true}
                className={classes.list__Item_resp}
                to="/#team"
              >
                Our Team
              </Link>
            </Box> */}
            <Box component={"li"}>
              <Button className={classes.contact___btn}>Contact Us</Button>
            </Box>
          </List>
        </Dialog>
      </Box>
    </>
  );
};

export default Header;
