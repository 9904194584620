import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { useTheme } from "@mui/material/styles";

// Import Swiper styles
import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/navigation";
import "swiper/css/pagination";

// import required modules
import { Navigation } from "swiper";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { Box, Button, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import star from "../images/star.svg";
import profile from "../images/profile.svg";

const useStyles = makeStyles({
  arrowBoxSecond: {
    display: "flex",
    justifyContent: "end",
    position: "relative",
    alignItems: "center",
    marginTop: "-1.5rem",
    zIndex: "100",
    marginRight: "4rem",
    gap: "2rem",
    "@media(max-width : 600px)": {
      marginTop: "1.5rem",
      justifyContent: "center",
      marginRight: "0rem",
    },
  },
  arrowBox: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: "2rem",
    position: "relative",
    alignItems: "center",
  },
  arrowbg: {
    backgroundColor: "#fff",
    padding: "10px",
    borderRadius: "5rem",
    height: "30px",
    width: "30px",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    boxShadow: "0px 0px 12px -2px #00000029",
    cursor: "pointer",
  },
  c_box_one: {
    display: "inline-block",
    boxShadow: "0px 0px 21px -2px #0000002b",
    padding: "1.5rem 3rem 1rem 3rem",
    textDecoration: "none",
    backgroundColor: "#fff",
    margin: "3rem 1rem 0rem 1rem",
    color: "#000",
    "@media(max-width : 600px)": {
      margin: "0rem 0rem",
      padding: "1.5rem 1rem 1rem 1rem",
      textAlign: "center",
    },
  },

  imgicon: {
    marginTop: "-4rem !important",
  },
  imgicon2: {
    marginTop: "0rem !important",
  },

  more_butn: {
    marginTop: "2rem !important",
    "&:hover": {
      backgroundColor: "transparent !important",
    },
  },

  location: {
    display: "flex",
    padding: "1rem 0rem",
    alignItems: "center",
    gap: "1.5rem",
    marginTop: "1rem",
    "@media(max-width : 600px)": {
      display: "inherit",
    },
  },
  case_cont: {
    padding: "2rem 2.5rem",
  },
});

export default function TestimonialSlider() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = "steps".length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const classes = useStyles();
  return (
    <>
      <Swiper
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        steps={maxSteps}
        activeStep={activeStep}
        navigation={{
          nextEl: ".image-swiper-button-next",
          prevEl: ".image-swiper-button-prev",
          disabledClass: "swiper-button-disabled",
        }}
        modules={[Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>
          <Box>
            <Link className={classes.c_box_one}>
              <Typography component={"img"} src={star} alt="" />

              <Typography mt={2} color={"#000"}>
                "Lorem Ipsum is simply dummy text of the printing and
                typesetting industry. Lorem Ipsum has been the industry's
                standard dummy text ever since the 1500s, when an unknown
                printer took a galley"
              </Typography>

              <Box className={classes.location}>
                <Box>
                  <Typography component={"img"} src={profile} alt="" />
                </Box>
                <Box>
                  <Typography
                    variant="h5"
                    fontFamily={"poppins"}
                    color={"#000"}
                    fontWeight={700}
                  >
                    Arnold Burner
                  </Typography>
                  <Typography
                    fontWeight={600}
                    color={"#445DD6"}
                    fontFamily={"poppins"}
                  >
                    Senior Developer
                  </Typography>
                </Box>
              </Box>
            </Link>
          </Box>
        </SwiperSlide>

        <SwiperSlide>
          <Box>
            <Link className={classes.c_box_one}>
              <Typography component={"img"} src={star} alt="" />

              <Typography mt={2} color={"#000"}>
                "Lorem Ipsum is simply dummy text of the printing and
                typesetting industry. Lorem Ipsum has been the industry's
                standard dummy text ever since the 1500s, when an unknown
                printer took a galley"
              </Typography>

              <Box className={classes.location}>
                <Box>
                  <Typography component={"img"} src={profile} alt="" />
                </Box>
                <Box>
                  <Typography
                    variant="h5"
                    fontFamily={"poppins"}
                    color={"#000"}
                    fontWeight={700}
                  >
                    Arnold Burner
                  </Typography>
                  <Typography
                    fontWeight={600}
                    color={"#445DD6"}
                    fontFamily={"poppins"}
                  >
                    Senior Developer
                  </Typography>
                </Box>
              </Box>
            </Link>
          </Box>
        </SwiperSlide>

        <SwiperSlide>
          <Box>
            <Link className={classes.c_box_one}>
              <Typography component={"img"} src={star} alt="" />

              <Typography mt={2} color={"#000"}>
                "Lorem Ipsum is simply dummy text of the printing and
                typesetting industry. Lorem Ipsum has been the industry's
                standard dummy text ever since the 1500s, when an unknown
                printer took a galley"
              </Typography>

              <Box className={classes.location}>
                <Box>
                  <Typography component={"img"} src={profile} alt="" />
                </Box>
                <Box>
                  <Typography
                    variant="h5"
                    fontFamily={"poppins"}
                    color={"#000"}
                    fontWeight={700}
                  >
                    Arnold Burner
                  </Typography>
                  <Typography
                    fontWeight={600}
                    color={"#445DD6"}
                    fontFamily={"poppins"}
                  >
                    Senior Developer
                  </Typography>
                </Box>
              </Box>
            </Link>
          </Box>
        </SwiperSlide>

        <SwiperSlide>
          <Box>
            <Link className={classes.c_box_one}>
              <Typography component={"img"} src={star} alt="" />

              <Typography mt={2} color={"#000"}>
                "Lorem Ipsum is simply dummy text of the printing and
                typesetting industry. Lorem Ipsum has been the industry's
                standard dummy text ever since the 1500s, when an unknown
                printer took a galley"
              </Typography>

              <Box className={classes.location}>
                <Box>
                  <Typography component={"img"} src={profile} alt="" />
                </Box>
                <Box>
                  <Typography
                    variant="h5"
                    fontFamily={"poppins"}
                    color={"#000"}
                    fontWeight={700}
                  >
                    Arnold Burner
                  </Typography>
                  <Typography
                    fontWeight={600}
                    color={"#445DD6"}
                    fontFamily={"poppins"}
                  >
                    Senior Developer
                  </Typography>
                </Box>
              </Box>
            </Link>
          </Box>
        </SwiperSlide>

        <SwiperSlide>
          <Box>
            <Link className={classes.c_box_one}>
              <Typography component={"img"} src={star} alt="" />

              <Typography mt={2} color={"#000"}>
                "Lorem Ipsum is simply dummy text of the printing and
                typesetting industry. Lorem Ipsum has been the industry's
                standard dummy text ever since the 1500s, when an unknown
                printer took a galley"
              </Typography>

              <Box className={classes.location}>
                <Box>
                  <Typography component={"img"} src={profile} alt="" />
                </Box>
                <Box>
                  <Typography
                    variant="h5"
                    fontFamily={"poppins"}
                    color={"#000"}
                    fontWeight={700}
                  >
                    Arnold Burner
                  </Typography>
                  <Typography
                    fontWeight={600}
                    color={"#445DD6"}
                    fontFamily={"poppins"}
                  >
                    Senior Developer
                  </Typography>
                </Box>
              </Box>
            </Link>
          </Box>
        </SwiperSlide>
      </Swiper>

      <Box className={classes.arrowBoxSecond}>
        <Box className="swiper-button image-swiper-button-prev">
          <Button
            sx={{
              backgroundColor: "#fff",
              padding: "10px",
              color: "#000",
              borderRadius: "5rem",
              height: "50px",
              width: "50px",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              boxShadow: "0px 0px 12px -2px #00000029",
              cursor: "pointer",
              minWidth: "50px",
              "&:hover": {
                backgroundColor: "#7114EF",
                color: "#fff",
              },
            }}
            className={classes.arrowbg}
            onClick={handleBack}
            disabled={activeStep === 0}
          >
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
          </Button>
        </Box>

        <Box className="swiper-button image-swiper-button-next">
          <Button
            sx={{
              backgroundColor: "#fff",
              padding: "10px",
              color: "#000",
              borderRadius: "5rem",
              height: "50px",
              width: "50px",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              boxShadow: "0px 0px 12px -2px #00000029",
              cursor: "pointer",
              minWidth: "50px",
              "&:hover": {
                backgroundColor: "#7114EF",
                color: "#fff",
              },
            }}
            className={classes.arrowbg}
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        </Box>
      </Box>
    </>
  );
}
