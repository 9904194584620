import React, { useEffect } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import { Box, Container, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";

const PrivacyPolicy = () => {
    const location = useLocation();

    useEffect(() => {
      if (location.pathname === "/privacy-policy") {
        window.scrollTo(0, 0); // Scrolls to the top when Privacy Policy page is loaded
      }
    }, [location]);
  return (
    <div>
      <Header />
      <Container>
        <Box py={"2rem"}>
        <Typography
            sx={{
                fontSize:{sm:"2.5rem",xs:"1.6rem"},
              pb: "1.5rem",
              fontWeight:"600",
              textAlign:"center"
            }}
          >
       Privacy Policy
          </Typography>
          <Typography
            sx={{
              pb: "1rem",
            }}
          >
            Himotech Global ("we", "us", or "our") operates the{" "}
            <a
              href="https://himotechglobal.com/"
              target="_blank"
              rel="noreferrer"
            >
              https://himotechglobal.com
            </a>{" "}
            website ("Service"). This page informs you of our policies regarding
            the collection, use, and disclosure of personal data when you use
            our Service and the choices you have associated with that data. We
            use your data to provide and improve the Service. By opting in, you
            agree to the collection and use of information by this policy.
          </Typography>
          <Typography
            sx={{
              pb: "1rem",
              fontWeight: "600",
            }}
          >
            Information Collection and Use
          </Typography>
          <Typography
            sx={{
              pb: "1rem",
            }}
          >
            We collect several different types of information for various
            purposes to provide and improve our Service to you.
          </Typography>
          <Typography
            sx={{
              pb: "1rem",
              fontWeight: "600",
            }}
          >
            Types of Data Collected:
          </Typography>
          <Typography
            sx={{
              pb: "1rem",
            }}
          >
            <b>- Personal Data:</b> While using our Service, we may ask you to
            provide us with certain personally identifiable information that can
            be used to contact or identify you ("Personal Data"). Personally
            identifiable information may include, but is not limited to:
          </Typography>
          <Typography
            sx={{
              pb: "1rem",
              ml:"0.4rem"
            }}
          >
        
            - Email address
            <br />
            - First name and last name
            <br />- Cookies and Usage Data
          </Typography>
          <Typography
            sx={{
              fontWeight: "600",
              pb: "1rem",
            }}
          >
            Use of Data
          </Typography>
          <Typography
            sx={{
              pb: "1rem",
            }}
          >
            Himotech Global uses the collected data for various purposes:
          </Typography>
          <Typography
            sx={{
              pb: "1rem",
              ml:"0.4rem"
            }}
          >
            - To provide and maintain our Service
            <br />
            - To notify you about changes to our Service
            <br />
            - To allow you to participate in interactive features of our Service
            when you choose to do so
            <br />
            - To provide customer support
            <br />
            - To gather analysis or valuable information so that we can improve
            our Service
            <br />
            - To monitor the usage of our Service
            <br />- To detect, prevent, and address technical issues
          </Typography>

          <Typography
            sx={{
              fontWeight: "600",
              pb: "1rem",
            }}
          >
            Transfer of Data
          </Typography>
          <Typography
            sx={{
              pb: "1rem",
            }}
          >
            Your information, including Personal Data, may be transferred to —
            and maintained on — computers located outside of your state,
            province, country, or other governmental jurisdiction where the data
            protection laws may differ from those from your jurisdiction.
          </Typography>
          <Typography
            sx={{
              pb: "1rem",
            }}
          >
            Himotech Global will take all steps reasonably necessary to ensure
            that your data is treated securely and by this Privacy Policy and no
            transfer of your Personal Data will take place to an organization or
            a country unless there are adequate controls in pla ce including the
            security of your data and other personal information.
          </Typography>
          <Typography
            sx={{
              pb: "1rem",
              fontWeight: "600",
            }}
          >
            Disclosure of Data
          </Typography>
          <Typography
            sx={{
              pb: "1rem",
              // fontWeight:"600"
            }}
          >
            Himotech Global may disclose your Personal Data in the good faith
            belief that such action is necessary to:
          </Typography>
          <Typography
            sx={{
              pb: "1rem",
              ml:"0.4rem"
              // fontWeight:"600"
            }}
          >
            - To comply with a legal obligation
            <br />
            - To protect and defend the rights or property of Himotech Global 
            <br />
            - To prevent or investigate possible wrongdoing in connection with
            the Service
            <br />
            - To protect the personal safety of users of the Service or the
            public
            <br />- To protect against legal liability
          </Typography>

        </Box>
      </Container>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
