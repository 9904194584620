import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import AOS from "aos";
import "aos/dist/aos.css";
import { useFormik } from "formik";
import * as yup from "yup";
import { API_URl, SITE_KEY } from "../config/config";
import axios from "axios";

const useStyles = makeStyles({
  TextField__box: {
    border: "1px solid #f5f8f863",
    padding: "0.3rem 1rem",
    boxShadow: "0px 3px 5px -2px #0000002e",
    borderRadius: "8px",
    color: "white !important",
  },
  TextField__box2: {
    border: "1px solid #f5f8f863",
    padding: "0.3rem 1rem 3rem 1rem",
    boxShadow: "0px 3px 5px -2px #0000002e",
    borderRadius: "8px",
  },
  btn__bg: {
    "@media(max-width : 900px)": {
      marginTop: "2rem",
    },
  },
  contact___btn: {
    background: "linear-gradient(145deg, #3E71E3, #591EAC)",
    backgroundColor: "transparent !important",
    color: "#fff !important",
    fontFamily: "poppins !important",
    padding: "0.6rem 2rem !important",
    borderRadius: "5rem !important",
    transition: "0.5s !important",
    "&:hover": {
      transform: "translateY(-5px)",
    },
    "@media(max-width : 900px)": {
      padding: "0.8rem 1.5rem !important",
    },
  },
  logo_wrap: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  logo__box: {
    backgroundColor: "#fff",
    padding: "0.6rem 1rem 0rem 1rem",
    borderRadius: "12px",
    margin: "0rem 1.5rem",
    opacity: "0.9",
  },
  error: {
    color: "#f14668",
    fontSize: "14px !important",
    paddingTop: "3px",
    wordBreak: "break-all",
  },
});

const ModalForm = ({ handleSuccessOpen, handleMessage }) => {
  const [recaptcha, setRecaptcha] = useState(false);
  const [recaptchaError, setRecaptchaError] = useState(false);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.google.com/recaptcha/api.js";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
    window.recaptchaCallback = function () {
      setRecaptcha(true);
    };
  }, [handleSuccessOpen]);
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  // eslint-disable-next-line no-unused-vars
  const [scroll, setScroll] = React.useState("paper");

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const validationSchema = yup.object({
    fname: yup.string().required("Required").trim(),
    lname: yup.string().required("Required").trim(),
    service: yup.string().required("Required").trim(),
    email: yup
      .string()
      .required("Required")
      .trim()
      .test("Please enter valid email", function (value) {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
      }),
    phone: yup
      .string()
      .trim("Phone number should not contain spaces")
      .required("Required")
      .length(10, "Phone number should be exactly 10 digits")
      .test("is-numeric", "Please enter a numeric value", function (value) {
        // Check if the value is numeric
        return /^[0-9]+$/.test(value);
      }),
    message: yup.string().required("Required").trim(),
    check: yup
      .boolean()
      .oneOf([true], "You must accept the terms and conditions"),
  });

  const formik = useFormik({
    initialValues: {
      fname: "",
      lname: "",
      email: "",
      phone: "",
      message: "",
      service: "",
      check: false,
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      if (!recaptcha) {
        setRecaptchaError(true); // Set recaptcha error if not ticked
      } else {
        let url = `${API_URl}/register`;
        axios
          .post(url, values)
          .then((response) => {
            // Handle success
            if (response.status === 200) {
              handleMessage(
                <>
                  Thanks for showing interest,
                  <br /> will get back to you
                </>
              );
              handleSuccessOpen();
              handleClose();
            }
          })
          .catch((error) => {
            // Handle error
            console.error("Error:", error.message);
          });
        resetForm();
      }
    },
  });

  const handleClose = () => {
    setOpen(false);
  };

  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <Box className={classes.btn__bg}>
        <Button
          data-aos="fade-left"
          data-aos-duration="500"
          onClick={handleClickOpen("body")}
          variant="contained"
          className={classes.contact___btn}
        >
          Get Connected
        </Button>
      </Box>

      <Box>
        <Dialog
          sx={{
            backgroundColor: "#0000003b",

            "& .MuiPaper-root": {
              backgroundColor: "#000018",
              border: "1px solid #002cffbd",
              width: "500px",
              borderRadius: "10px",
            },
          }}
          open={open}
          onClose={handleClose}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h6" fontWeight={700} color={"#fff"}>
                Get in touch
              </Typography>
              <IconButton onClick={handleClose}>
                <ClearIcon sx={{ color: "#fff" }} />
              </IconButton>
            </Box>
          </DialogTitle>

          <DialogContent dividers={scroll === "paper"}>
            <Box>
              {/* <Box component={"form"}> */}
              <form onSubmit={formik.handleSubmit}>
                <Box className={classes.TextField__box}>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    variant="standard"
                    name="fname"
                    value={formik.values.name}
                    placeholder="Enter Your First Name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    InputProps={{
                      style: { color: "white" },
                      disableUnderline: true,
                    }}
                    sx={{
                      color: "white !important",
                    }}
                  />
                  {formik.touched.fname && formik.errors.fname && (
                    <Typography variant="body1" className={classes.error}>
                      {formik.errors.fname}
                    </Typography>
                  )}
                </Box>

                <Box height={15} />

                <Box className={classes.TextField__box}>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    variant="standard"
                    name="lname"
                    value={formik.values.lname}
                    placeholder="Enter Your Last Name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    InputProps={{
                      style: { color: "#fff" },
                      disableUnderline: true,
                    }}
                  />
                  {formik.touched.lname && formik.errors.lname && (
                    <Typography variant="body1" className={classes.error}>
                      {formik.errors.lname}
                    </Typography>
                  )}
                </Box>

                <Box height={15} />

                <Box className={classes.TextField__box}>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    variant="standard"
                    type="email"
                    name="email"
                    value={formik.values.email}
                    placeholder="Enter Your Email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    InputProps={{
                      style: { color: "#fff" },
                      disableUnderline: true,
                    }}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <Typography variant="body1" className={classes.error}>
                      {formik.errors.email}
                    </Typography>
                  )}
                </Box>
                <Box height={15} />

                <Box className={classes.TextField__box}>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    variant="standard"
                    name="service"
                    type="text"
                    value={formik.values.service}
                    placeholder="Enter Service Name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    InputProps={{
                      style: { color: "#fff" },
                      disableUnderline: true,
                    }}
                  />
                  {formik.touched.service && formik.errors.service && (
                    <Typography variant="body1" className={classes.error}>
                      {formik.errors.service}
                    </Typography>
                  )}
                </Box>
                <Box height={15} />

                <Box className={classes.TextField__box}>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    variant="standard"
                    name="phone"
                    type="number"
                    value={formik.values.phone}
                    placeholder="Enter Your Phone Number"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    InputProps={{
                      style: { color: "#fff" },
                      disableUnderline: true,
                    }}
                  />
                  {formik.touched.phone && formik.errors.phone && (
                    <Typography variant="body1" className={classes.error}>
                      {formik.errors.phone}
                    </Typography>
                  )}
                </Box>

                <Box height={15} />

                <Box className={classes.TextField__box2}>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    variant="standard"
                    multiline
                    rows={2}
                    maxRows={3}
                    name="message"
                    value={formik.values.message}
                    placeholder="Message...."
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    InputProps={{
                      style: { color: "#fff" },
                      disableUnderline: true,
                    }}
                  />
                  {formik.touched.message && formik.errors.message && (
                    <Typography variant="body1" className={classes.error}>
                      {formik.errors.message}
                    </Typography>
                  )}
                </Box>

                <Box height={15} />
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "baseline",
                  }}
                >
                  <Box>
                    <Checkbox
                      style={{ color: "#2979ff" }}
                      {...label}
                      name="check"
                      value={formik.values.check}
                      placeholder="Message...."
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </Box>
                  <Box>
                    <Typography color={"#999"} fontSize={"13px"}>
                      I Agree to himotech collecting & processing my personal
                      data to allow me to receive information on himotech
                      services. For further information, please see our Privacy
                      Notice.{" "}
                    </Typography>
                  </Box>
                </Box>
                {formik.touched.check && formik.errors.check && (
                  <Typography variant="body1" className={classes.error}>
                    {formik.errors.check}
                  </Typography>
                )}
                <Typography
                  sx={{
                    pt: "1rem",
                    fontSize: { md: "16px", xs: "16px" },
                    fontFamily: "Nunito",
                    color: "#fff",
                  }}
                >
                  This site is protected by <b>reCAPTCHA</b> and the Google{" "}
                  <b>Privacy Policy</b> and <b>Terms of Service</b> apply.
                </Typography>

                <div
                  style={{
                    marginTop: "1rem",
                    display: "flex",
                    justifyContent: "center",
                  }}
                  className="g-recaptcha"
                  data-sitekey={SITE_KEY}
                  data-callback="recaptchaCallback"
                ></div>
                {!recaptcha && recaptchaError && (
                  <Typography
                    sx={{ textAlign: "center !important" }}
                    className={classes.error}
                  >
                    Please tick above recaptcha.
                  </Typography>
                )}
                <Box height={15} />
                <Box textAlign={"center"}>
                  <Button
                    variant="contained"
                    type="submit"
                    className={classes.contact___btn}
                  >
                    Submit
                  </Button>
                </Box>
              </form>
              {/* </Box> */}
            </Box>
          </DialogContent>
        </Dialog>
      </Box>
    </>
  );
};

export default ModalForm;
