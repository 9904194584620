/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Header from "../components/header/Header";
import Banner from "../components/homeComponent/Banner";
import HappyClient from "../components/homeComponent/HappyClient";
import CompanyService from "../components/homeComponent/CompanyService";
import Dedicated from "../components/homeComponent/Dedicated";
import Case from "../components/homeComponent/Case";
import WhyChoose from "../components/homeComponent/WhyChoose";
import Testimonial from "../components/homeComponent/Testimonial";
import Qoute from "../components/homeComponent/Qoute";
import Footer from "../components/footer/Footer";
import { Box } from "@mui/material";
import Team from "../components/homeComponent/Team";
import PopUpModal from "./PopUpModal";
import SuccessPop from "../components/SuccessPop/SuccessPop";

const Home = () => {

  // const [width, setWidth] = useState(window.innerWidth);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [message, setMessage] = useState("");
  const [successOpen, setSuccessOpen] = useState(false);
  const handleSuccessOpen = () => setSuccessOpen(true);
  const handleSuccessClose = () => setSuccessOpen(false);
  const handleMessage = (value) => {
    setMessage(value);
  };

  // function handleWindowSizeChange() {
  //   setWidth(window.innerWidth);
  // }

  useEffect(() => {
    if (!open) {
      setTimeout(() => {
        handleOpen();
      }, 3000);
    }

    if (window.location.href.includes("#team")) {
      window.scrollTo(4500, 5000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* <TopHeader /> */}
      <Box>
        <Header />
        <Banner />
        <HappyClient />
        <Box component={"section"} id={"about"}>
          <CompanyService />
        </Box>
        <Box component={"section"} id={"services"}>
          <Dedicated />
        </Box>
        {/* <Case /> */}
        <Box component={"section"} id={"benefits"}>
          <WhyChoose />
        </Box>
        <Box component={"section"} id={"team"}>
          {/* <Team /> */}
        </Box>
        {/* <Testimonial /> */}
        <Qoute
          handleSuccessOpen={handleSuccessOpen}
          handleMessage={handleMessage}
        />
        <Footer
          handleSuccessOpen={handleSuccessOpen}
          handleMessage={handleMessage}
        />
      </Box>
      <PopUpModal
        open={open}
        handleClose={handleClose}
        handleSuccessOpen={handleSuccessOpen}
        handleMessage={handleMessage}
      />
      <SuccessPop
        open={successOpen}
        handleClose={handleSuccessClose}
        message={message}
      />
    </>
  );
};

export default Home;
