import React, { useEffect } from "react";
import Header from "../components/header/Header";
// import firstlogo from "../components/images/Group 309.png";
// import circle from "../components/images/Group 304.png";
import Footer from "../components/footer/Footer";
import Qoute from "../components/homeComponent/Qoute";
// import dots from '../components/images/dot.png'

import "../Style/About.css";
// import { Group } from "@mui/icons-material";
import {  Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  br: {
    "@media(max-width : 600px)": {
      display: "none",
    },
  },
});

const About = () => {
  const classes = useStyles();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <div className="Main_Box">
        <h1 className="Main_Box_Heading ">About us</h1>
      </div>
      <div className="container">
        <div className="Pillar_Box">
          <div className="Heading_About col-md-12 col-sm-12">
            <h5 class="About_Heading">About Us</h5>
            <div className="Heading pt-3">
              {/* <h3 className="About_Us_pillar text-black">
              Fingent's Four <span className="Pillar_span"> Pillars </span> of
              Influence
            </h3> */}
              {/* <br /> */}
              {/* <p>
              “We believe in these four pillars of influence that drive our
              growth. <br /> This is ingrained in everything we do, right from
              our hiring practices to our work culture.”
            </p> */}
              <br /> <br />
            </div>
          </div>
          <div className="circle-point" style={{ padding: "20px" }}>
          At <b>Himotech Global,</b> we specialize in delivering a full spectrum of <b>IT solutions</b> designed to
enhance your business's digital presence. Established in 2021, we have consistently provided
tailored <b>IT services</b> to a global client base, focusing on meeting the unique needs of each
business we work with.
            <Typography className={classes.br} component={"br"} />
            <Typography className={classes.br} component={"br"} />
            Our core mission is to offer reliable, high-quality <b>IT solutions</b> that ensure accuracy and
efficiency. We prioritize delivering services that are <b>error-free</b> and fully refined, ensuring
optimal outcomes for our clients and stakeholders. As we continue to grow, we are committed to
expanding our services to adapt to the evolving needs of businesses in the digital landscape.

            <Typography className={classes.br} component={"br"} />
            <Typography className={classes.br} component={"br"} />
            <b>Himotech Global</b> offers a broad range of services to support your business's <b>digital
transformation:</b><br></br>
● <b>Web Design and Development:</b> Creating customized websites that meet the specific
needs of your business.<br></br>
● <b>DevOps Services:</b> Streamlining processes to improve operational efficiency and agility.<br></br>
● <b>Software Deployment:</b> Ensuring seamless integration and deployment of software
systems.<br></br>
● <b>Blockchain Development:</b> Providing innovative blockchain solutions to meet modern
business demands.<br></br>
● <b>WEB3 Services:</b> Supporting the transition to decentralized web solutions.<br></br>
● <b>Marketing: </b>Developing strategies to enhance your online presence and reach.<br></br>
● <b>Online Troubleshooting Support:</b> Offering dedicated support to resolve technical
challenges quickly and effectively<br></br>
            <Typography className={classes.br} component={"br"} />
            <Typography className={classes.br} component={"br"} />
            Our approach is to create <b>customized IT solutions</b> that align with your business objectives. We
focus on delivering solutions that are not only technologically sound but also relevant to your
target audience. By blending <b>IT expertise</b> with marketing insights, we help businesses optimize
their digital strategies and maximize their revenue potential.
            <Typography className={classes.br} component={"br"} />
            <Typography className={classes.br} component={"br"} />
            At <b>Himotech Global,</b> we operate with a team-driven approach, working collaboratively to
achieve the best results for our clients. This focus on teamwork and adaptability has been key to
our continued success
            <Typography className={classes.br} component={"br"} />
            <Typography className={classes.br} component={"br"} />
            Ready to take the next step? <b>Himotech Global</b> is here to support your business growth with
            professional <b>IT solutions.</b> Contact us today to discover how we can help your business thrive
            <Typography className={classes.br} component={"br"} />
            {/* <div className="row">
          <div className="col-md-4">
          <div className="elementorClass">
            <h6 class="elementor">
              Three guiding principles <br /> for Organization
            </h6>
            <ul>
              <li> <img  className="text_center" src={dots} alt="" />Impact the world</li>
              <li> <img  className="text_center" src={dots} alt="" />Great workplace</li>
              <li> <img  className="text_center" src={dots} alt="" />Sustainable business model</li>
            </ul>
          </div>
          </div>

          <div className="col-md-4">
            <img cl src={circle} alt="" width={"90%"} />
          </div>

          <div className="col-md-4">
            <h6 class="elementor ">
              Three guiding principles <br /> for Organization
            </h6>
            <ul >
              <li> <img className="text_center" src={dots} alt="" />Character</li>
              <li> <img  className="text_center" src={dots} alt="" />Personality</li>
              <li> <img  className="text_center" src={dots} alt="" />SkillSet</li>
            </ul>
          </div>
          </div> */}
          </div>
        </div>
        {/* <div className="row rowcustom">
          <div className="col-md-3 text-center">
            <div className="mainDiv fc">
              <div className="firstimagelogo">
                <img src={firstlogo} alt="" />
              </div>

              <div class="background_color_card card">
                <div class="card-body">
                  <h5 class="card-title">Customers</h5>
                  <div className="Line_Context"/> 
                  <br />
                  <p class="card-text-paragraph "   >
                    We develop relationships <br /> that make a positive
                    difference in ourcustomers' lives.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 text-center">
          <div className="mainDiv">
              <div className="firstimagelogo">
                <img src={firstlogo} alt="" />
              </div>
            <div class="background_color_card card">
              <div class="card-body">
                <h5 class="card-title">Peers</h5>
                <div className="Line_Context"/> 
                <br />
                <p class="card-text-paragraph ">
                  We value our people,encourage their development, and reward
                  their performance.
                </p>
              </div>
            </div>
          </div>
          </div>
          <div className="col-md-3 text-center rounded-circle">
          <div className="mainDiv">
              <div className="firstimagelogo">
                <img src={firstlogo} alt="" />
              </div>
            <div class="background_color_card card">
              <div class="card-body">
                <h5 class="card-title">Family & Self</h5>
                <div className="Line_Context"/> 
                <br />
                <p class="card-text-paragraph ">
                  We personally care for the well-being and work-life balance of
                  our associates and their families
                </p>
              </div>
            </div>
          </div>
          </div>
          <div className="Cards col-md-3 text-center">
          <div className="mainDiv">
              <div className="firstimagelogo">
                <img src={firstlogo} alt="" />
              </div>
            <div class="background_color_card card">
              <div class="card-body">
                <h5 class="card-title"> Society</h5>
                <div className="Line_Context"/> 
                <br />
                <p class="card-text-paragraph ">
                  We take key initiatives that benefit the needy and foster a
                  culture of giving back to society.
                </p>
              </div>
            </div>
          </div>
          </div>
        </div> */}

        <div className="row">
          <div className="col-md-5">
            <div className="first_paragraph">
              <h6 className="Our-Mission">
                Our <span className="Mission"> Mission</span>{" "}
              </h6>
              <div className="SingleLine" />
              <div class="Line"></div>
              <p style={{ paddingBottom: "1rem" }}>
                Our mission is to make technology accessible to everyone, regardless of their
                background or expertise, by harnessing the latest advancements in computer science
                and the insights of seasoned professionals.


              </p>
            </div>
          </div>
          <div className="col-md-2"></div>
          <div className="col-md-5">
            <div className="Second_paragraph">
              <h6 className="Our-Mission">
                Our <span className="Mission"> Vision</span>
              </h6>
              <div className="SingleLine" />
              <div class="Line"></div>
              <p>
                Our vision is to lead the charge in driving technological innovation, pushing boundaries
                to achieve the perfect fusion of science and art. As pioneers in the field, we strive to
                propel society forward through cutting-edge solutions that elevate the way we live and
                work.

              </p>
            </div>
          </div>
        </div>
      </div>
      <Qoute />
      <Footer />
    </>
  );
};

export default About;
