import { Box, Container, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import strategicbg from "../images/strategicbg.svg";
import resp_strategy from "../images/resp_strategy.svg";
import AOS from "aos";
import "aos/dist/aos.css";

const useStyles = makeStyles({
  mainBox__wrap: {
    background: `url(${strategicbg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
    padding: "9rem 4rem 9rem 4rem",
    textAlign: "center",
    "@media(max-width : 1200px)": {
      padding: "7rem 4rem 3rem 4rem",
      "@media(max-width : 900px)": {
        padding: "7rem 2rem 4rem 2rem",
        backgroundSize: "cover",
        "@media(max-width : 600px)": {
          padding: "2rem 1rem",
          background: `url(${resp_strategy})`,
          backgroundSize: "cover",
          marginTop: "2rem",
          borderRadius: "12px",
        },
      },
    },
  },
  br: {
    "@media(max-width : 600px)": {
      display: "none",
    },
  },
  strategic__box: {
    textAlign: "center",
    "@media(max-width : 600px)": {
      backgroundColor: "#ffffff33",
      padding: "2rem 0rem",
      borderRadius: "12px",
    },
  },
});

const Strategic = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  const classes = useStyles();
  return (
    <>
      <Box className={classes.mainBox__wrap}>
        <Container>
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box>
                <Typography
                  data-aos="fade-right"
                  data-aos-duration="500"
                  sx={{
                    fontSize: "30px",
                    paddingBottom: "2rem",
                    "@media(max-width:600px)": {
                      fontSize: "24px",
                    },
                  }}
                  variant="h4"
                  color={"#fff"}
                  fontWeight={900}
                  fontFamily={"poppins"}
                >
                  We develop strategic software solutions for businesses.
                </Typography>
                <Box>
                  <Grid container spacing={2}>
                    <Grid item lg={3} md={3} sm={3} xs={12}>
                      <Box
                        data-aos="zoom-in"
                        data-aos-duration="500"
                        className={classes.strategic__box}
                      >
                        <Typography
                          variant="h3"
                          color={"#00D8FF"}
                          fontWeight={500}
                          fontFamily={"poppins"}
                        >
                          2021
                        </Typography>
                        <Typography fontWeight={600} color={"#fff"}>
                          Year of{" "}
                          <Typography className={classes.br} component={"br"} />
                          establishment
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} xs={12}>
                      <Box
                        data-aos="zoom-in-up"
                        data-aos-duration="500"
                        className={classes.strategic__box}
                      >
                        <Typography
                          variant="h3"
                          color={"#00D8FF"}
                          fontWeight={500}
                          fontFamily={"poppins"}
                        >
                          120+
                        </Typography>
                        <Typography fontWeight={600} color={"#fff"}>
                        Project 
                        <Typography className={classes.br} component={"br"} />
                        Delivery
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} xs={12}>
                      <Box
                        data-aos="zoom-in"
                        data-aos-duration="500"
                        className={classes.strategic__box}
                      >
                        <Typography
                          variant="h3"
                          color={"#00D8FF"}
                          fontWeight={500}
                          fontFamily={"poppins"}
                        >
                         100% 
                        </Typography>
                        <Typography fontWeight={600} color={"#fff"}>
                          Project Running 
                          <Typography className={classes.br} component={"br"} />
                          Worldwide
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} xs={12}>
                      <Box
                        data-aos="zoom-in-up"
                        data-aos-duration="500"
                        className={classes.strategic__box}
                      >
                        <Typography
                          variant="h3"
                          color={"#00D8FF"}
                          fontWeight={500}
                          fontFamily={"poppins"}
                        >
                          4.9/5
                        </Typography>
                        <Typography fontWeight={600} color={"#fff"}>
                          Customer{" "}
                          <Typography className={classes.br} component={"br"} />
                          rating
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                  <Typography
                    data-aos="fade-left"
                    data-aos-duration="500"
                    sx={{
                      paddingTop: "3rem",
                      "@media(max-width : 900px)": { paddingTop: "1rem" },
                    }}
                    fontWeight={600}
                    color={"#fff"}
                  >
                    You have better things to do than worry about IT for your
                    business.
                    <Typography
                      component={"span"}
                      fontWeight={600}
                      color={"#000"}
                    >
                      {" "}
                      Let's Discuss about Project.
                    </Typography>
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Strategic;
