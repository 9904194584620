import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { makeStyles } from "@mui/styles";
import caseslider1 from "../images/caseslider1.svg";
import caseslider2 from "../images/caseslider2.svg";
import caseslider3 from "../images/caseslider3.svg";
import { Link } from "react-router-dom";
import { Container } from "@mui/material";
import AOS from "aos";
import "aos/dist/aos.css";

const useStyle = makeStyles({
  mainbox: {
    flexGrow: 1,
    cursor: "pointer",
    padding: "30px !important",
    boxShadow: "inset 0px 7px 15px -4px #00000024",
    borderRadius: "40px",
    backgroundColor: "#efefef96",
    "@media(max-width : 600px)": {
      padding: "50px 30px 30px 30px !important",
    },
  },

  c_box_one: {
    display: "inline-block",
    border: "1.6px solid #707070",
    height: "100%",
    borderRadius: "12px",
    textDecoration: "none",
    backgroundColor: "#fff",
    margin: "0rem 1rem",
    color: "#000",
    "@media(max-width : 600px)": {
      margin: "0rem 1rem",
      width: "320px",
    },
  },
  location: {
    display: "flex",
    padding: "1rem 0rem",
    "@media(max-width : 900px)": {
      justifyContent: "center",
    },
  },
  case_cont: {
    padding: "2rem 2.5rem",
    "@media(max-width : 900px)": {
      padding: "1rem 0.5rem",
    },
  },
  hdingS: {
    "@media(max-width : 1200px)": {
      fontSize: "1.2rem !important",
    },
  },
});

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
// const AutoPlaySwipeableViews = SwipeableViews;

const images = [
  {
    imgPath: caseslider1,
  },
  {
    imgPath: caseslider1,
  },
  {
    imgPath: caseslider1,
  },
  {
    imgPath: caseslider1,
  },
  {
    imgPath: caseslider1,
  },
];

function CaseSlider2() {
  const classes = useStyle();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  // eslint-disable-next-line no-unused-vars
  const maxSteps = images.length;

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  React.useEffect(() => {
    AOS.init();
  }, []);

  const handleLinkClick = (event) => {
    window.open(event, "_blank");
  };

  return (
    <>
      <Box sx={{ "@media(max-width : 900px)": { display: "none" } }}>
        <AutoPlaySwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
        >
          {images.map((step, index) => (
            <Box
              sx={{
                display: "flex",
                marginLeft: "11rem",
                "@media(max-width : 1200px)": { marginLeft: "0rem" },
              }}
            >
              <Box key={step.label} className={classes.imgbox}>
                {Math.abs(activeStep - index) <= 2 ? (
                  <Link
                    data-aos="flip-left"
                    data-aos-duration="500"
                    className={classes.c_box_one}
                  >
                    <Typography
                      component={"img"}
                      src={caseslider1}
                      alt=""
                      width={"100%"}
                    />
                    <Box className={classes.case_cont}>
                      <Typography
                        className={classes.hdingS}
                        variant="h5"
                        color={"#000"}
                        fontWeight={700}
                        sx={{
                          padding: "0.3rem 0rem",
                        }}
                      >
                        Product Engineering
                      </Typography>
                      <Typography
                        component={"span"}
                        onClick={() =>
                          handleLinkClick(
                            "https://www.nalashaa.com/java-in-software-product-engineering/"
                          )
                        }
                        sx={{ color: "blue", textDecoration: "underline" }}
                      >
                        Product Engineering
                      </Typography>
                      <Typography
                        color={"#000"}
                        sx={{ padding: "0.3rem 0rem" }}
                      >
                        Java in Software Product Engineering: The Long-Lasting
                        Edge
                      </Typography>
                    </Box>
                  </Link>
                ) : null}
              </Box>

              <Box key={step.label} className={classes.imgbox}>
                {Math.abs(activeStep - index) <= 2 ? (
                  <Link
                    data-aos="flip-right"
                    data-aos-duration="500"
                    className={classes.c_box_one}
                  >
                    <Typography
                      component={"img"}
                      src={caseslider2}
                      alt=""
                      width={"100%"}
                    />

                    <Box className={classes.case_cont}>
                      <Typography
                        className={classes.hdingS}
                        variant="h5"
                        color={"#000"}
                        fontWeight={700}
                        sx={{ padding: "0.1rem 0rem" }}
                      >
                        Firewall Advance
                      </Typography>
                      <Typography
                        component={"span"}
                        onClick={() =>
                          handleLinkClick(
                            "https://www.paloaltonetworks.com/blog/2021/01/netsec-zero-trust-across-workloads/"
                          )
                        }
                        sx={{ color: "blue", textDecoration: "underline" }}
                      >
                        Firewall Advance
                      </Typography>
                      <Typography
                        color={"#000"}
                        sx={{ padding: "0.2rem 0rem" }}
                      >
                        6 Reasons Our Firewall Platform is Critical for Zero
                        Trust Across Workloads
                      </Typography>
                    </Box>
                  </Link>
                ) : null}
              </Box>

              <Box key={step.label} className={classes.imgbox}>
                {Math.abs(activeStep - index) <= 2 ? (
                  <Link
                    data-aos="flip-left"
                    data-aos-duration="500"
                    className={classes.c_box_one}
                  >
                    <Typography
                      component={"img"}
                      src={caseslider3}
                      alt=""
                      width={"100%"}
                    />

                    <Box className={classes.case_cont}>
                      <Typography
                        className={classes.hdingS}
                        variant="h5"
                        color={"#000"}
                        fontWeight={700}
                        sx={{ padding: "0.2rem 0rem" }}
                      >
                        Data Management
                      </Typography>
                      <Typography
                        component={"span"}
                        onClick={() =>
                          handleLinkClick(
                            "https://denodo.medium.com/data-security-and-governance-enhanced-by-semantics-a90ad1c210bb"
                          )
                        }
                        sx={{ color: "blue", textDecoration: "underline" }}
                      >
                        Data Management
                      </Typography>
                      <Typography
                        color={"#000"}
                        sx={{ padding: "0.2rem 0rem" }}
                      >
                        Data Security and Governance, Enhanced by Semantics
                      </Typography>
                    </Box>
                  </Link>
                ) : null}
              </Box>
            </Box>
          ))}
        </AutoPlaySwipeableViews>

        <Container>
          <MobileStepper
            variant="progress"
            steps={5}
            position="static"
            activeStep={activeStep}
            sx={{
              flexGrow: 1,
              background: "transparent",
              marginTop: "2rem",
              "& .MuiLinearProgress-root": { width: "80%" },
              "& .MuiLinearProgress-bar": { backgroundColor: "#7114EF" },
            }}
            nextButton={
              <Button
                data-aos="flip-left"
                data-aos-duration="500"
                sx={{
                  backgroundColor: "#fff",
                  padding: "10px",
                  borderRadius: "5rem",
                  color: "#000",
                  height: "50px",
                  width: "50px",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  boxShadow: "0px 0px 12px -2px #00000029",
                  cursor: "pointer",
                  minWidth: "50px",
                  "&:hover": {
                    backgroundColor: "#7114EF",
                    color: "#fff",
                  },
                }}
                size="small"
                onClick={handleNext}
                disabled={activeStep === 4}
              >
                {theme.direction === "rtl" ? (
                  <KeyboardArrowLeft />
                ) : (
                  <KeyboardArrowRight />
                )}
              </Button>
            }
            backButton={
              <Button
                data-aos="flip-right"
                data-aos-duration="500"
                sx={{
                  backgroundColor: "#fff",
                  padding: "10px",
                  color: "#000",
                  borderRadius: "5rem",
                  height: "50px",
                  width: "50px",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  boxShadow: "0px 0px 12px -2px #00000029",
                  cursor: "pointer",
                  minWidth: "50px",
                  "&:hover": {
                    backgroundColor: "#7114EF",
                    color: "#fff",
                  },
                }}
                size="small"
                onClick={handleBack}
                disabled={activeStep === 0}
              >
                {theme.direction === "rtl" ? (
                  <KeyboardArrowRight />
                ) : (
                  <KeyboardArrowLeft />
                )}
              </Button>
            }
          />
        </Container>
      </Box>

      <Box
        sx={{
          display: "none",
          "@media(max-width : 900px)": { display: "block" },
          "@media(max-width : 600px)": { padding: "0rem 1rem" },
        }}
      >
        <AutoPlaySwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
        >
          {images.map((step, index) => (
            <Box
              sx={{
                display: "flex",
                marginLeft: "11rem",
                "@media(max-width : 1200px)": { marginLeft: "0rem" },
              }}
            >
              <Box key={step.label} className={classes.imgbox}>
                {Math.abs(activeStep - index) <= 2 ? (
                  <Link
                    data-aos="flip-left"
                    data-aos-duration="500"
                    className={classes.c_box_one}
                  >
                    <Typography
                      component={"img"}
                      src={caseslider1}
                      alt=""
                      width={"100%"}
                    />
                    <Box className={classes.case_cont}>
                      <Typography
                        className={classes.hdingS}
                        variant="h5"
                        color={"#000"}
                        fontWeight={700}
                        sx={{
                          padding: "0.1rem 0rem",
                        }}
                      >
                        Product Engineering
                      </Typography>
                      <Typography
                        component={"span"}
                        onClick={() =>
                          handleLinkClick(
                            "https://www.nalashaa.com/java-in-software-product-engineering/"
                          )
                        }
                        sx={{ color: "blue", textDecoration: "underline" }}
                      >
                        Product Engineering
                      </Typography>
                      <Typography
                        color={"#000"}
                        sx={{ padding: "0.2rem 0rem" }}
                      >
                        Java in Software Product Engineering: The Long-Lasting
                        Edge
                      </Typography>
                    </Box>
                  </Link>
                ) : null}
              </Box>

              {/* <Box key={step.label} className={classes.imgbox}>
                {Math.abs(activeStep - index) <= 2 ? (
                  <Link
                    data-aos="flip-right"
                    data-aos-duration="500"
                    className={classes.c_box_one}
                  >
                    <Typography
                      component={"img"}
                      src={caseslider2}
                      alt=""
                      width={"100%"}
                    />

                    <Box className={classes.case_cont}>
                      <Typography
                        className={classes.hdingS}
                        variant="h5"
                        color={"#000"}
                        fontWeight={700}
                        sx={{padding:'0.1rem 0rem'}}
                      >
                        Firewall Advance
                      </Typography>
                      <Typography
                        component={"span"}
                        onClick={() =>
                          handleLinkClick(
                            "https://www.paloaltonetworks.com/blog/2021/01/netsec-zero-trust-across-workloads/"
                          )
                        }
                        sx={{ color: "blue", textDecoration: "underline" }}
                      >
                        Firewall Advance
                      </Typography>
                      <Typography color={"#000"} sx={{padding:'0.2rem 0rem'}} >
                        6 Reasons Our Firewall Platform is Critical for Zero
                        Trust Across Workloads
                      </Typography>
                    </Box>
                  </Link>
                ) : null}
              </Box>

              <Box key={step.label} className={classes.imgbox}>
                {Math.abs(activeStep - index) <= 2 ? (
                  <Link
                    data-aos="flip-left"
                    data-aos-duration="500"
                    className={classes.c_box_one}
                  >
                    <Typography
                      component={"img"}
                      src={caseslider3}
                      alt=""
                      width={"100%"}
                    />

                    <Box className={classes.case_cont}>
                      <Typography
                        className={classes.hdingS}
                        variant="h5"
                        color={"#000"}
                        fontWeight={700}
                        sx={{padding:'0.2rem 0rem'}}
                      >
                        Data Management
                      </Typography>
                      <Typography
                        component={"span"}
                        onClick={() =>
                          handleLinkClick(
                            "https://denodo.medium.com/data-security-and-governance-enhanced-by-semantics-a90ad1c210bb"
                          )
                        }
                        sx={{ color: "blue", textDecoration: "underline" }}
                      >
                        Data Management
                      </Typography>
                      <Typography color={"#000"} sx={{padding:'0.2rem 0rem'}}>
                        Data Security and Governance, Enhanced by Semantics
                      </Typography>
                    </Box>
                  </Link>
                ) : null}
              </Box> */}
            </Box>
          ))}
        </AutoPlaySwipeableViews>

        <Container>
          <MobileStepper
            variant="progress"
            steps={5}
            position="static"
            activeStep={activeStep}
            sx={{
              flexGrow: 1,
              background: "transparent",
              marginTop: "2rem",
              "& .MuiLinearProgress-root": {
                width: "80%",
                "@media(max-width : 600px)": { width: "50%" },
              },
              "& .MuiLinearProgress-bar": { backgroundColor: "#7114EF" },
            }}
            nextButton={
              <Button
                sx={{
                  backgroundColor: "#fff",
                  padding: "10px",
                  borderRadius: "5rem",
                  color: "#000",
                  height: "50px",
                  width: "50px",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  boxShadow: "0px 0px 12px -2px #00000029",
                  cursor: "pointer",
                  minWidth: "50px",
                  "&:hover": {
                    backgroundColor: "#7114EF",
                    color: "#fff",
                  },
                }}
                size="small"
                onClick={handleNext}
                disabled={activeStep === 4}
              >
                {theme.direction === "rtl" ? (
                  <KeyboardArrowLeft />
                ) : (
                  <KeyboardArrowRight />
                )}
              </Button>
            }
            backButton={
              <Button
                sx={{
                  backgroundColor: "#fff",
                  padding: "10px",
                  borderRadius: "5rem",
                  color: "#000",
                  height: "50px",
                  width: "50px",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  boxShadow: "0px 0px 12px -2px #00000029",
                  cursor: "pointer",
                  minWidth: "50px",
                  "&:hover": {
                    backgroundColor: "#7114EF",
                    color: "#fff",
                  },
                }}
                size="small"
                onClick={handleBack}
                disabled={activeStep === 0}
              >
                {theme.direction === "rtl" ? (
                  <KeyboardArrowRight />
                ) : (
                  <KeyboardArrowLeft />
                )}
              </Button>
            }
          />
        </Container>
      </Box>
    </>
  );
}

export default CaseSlider2;
